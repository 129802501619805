import React from 'react'
import Style from './style.module.scss'

const index = ({ src, scriptName }) => {
  return (
    <div className={Style.container}>
      <iframe className={Style.video_player} src={`${src}?autoplay=1`} title={scriptName} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  )
}

export default index
