import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getCategoriesApi,
  getProductByCategoryApi,
  getProductApi,
  getAllProductsApi,
  buyProductsApi,
  getUserProductsApi,
  changeUserProductIpApi,
  getPopularProductApi,
  getAllUserProductsApi,
  getUserProductsCalculateApi,
  getAllSellApi,
} from 'api'

const initialState = {
  isLoading: false,
  products: [],
  userProducts: [],
  allSells: [],
  categories: [],
  product: null,
  pagination: {},
  message: null,
  messageType: null,
  error: null,
}

export const getProduct = createAsyncThunk('product/getProduct', async (id, { rejectWithValue }) => {
  try {
    const data = await getProductApi(id)
    if (data) {
      return data
    }
    throw new Error('Product not found')
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getCategories = createAsyncThunk('product/getCategories', async (args, { rejectWithValue }) => {
  try {
    const data = await getCategoriesApi()
    if (data) {
      return data
    }
    throw new Error('Failed to get categories')
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getProductByCategory = createAsyncThunk('product/getProductByCategory', async ({ id, page, perPage, search }, { rejectWithValue }) => {
  try {
    const data = await getProductByCategoryApi(id, page, perPage, search)
    if (data) {
      return data
    }
    throw new Error('Category not found')
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getAllProducts = createAsyncThunk('product/getAllProducts', async ({ page, perPage, search }, { rejectWithValue }) => {
  try {
    const data = await getAllProductsApi(page, perPage, search)
    if (data) {
      return data
    }
    throw new Error('Products not found')
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getPopularProduct = createAsyncThunk('product/getPopularProduct', async (args, { rejectWithValue }) => {
  try {
    const data = await getPopularProductApi()
    if (data) {
      return data
    }
    throw new Error('Popular Products not found')
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getUserProducts = createAsyncThunk('product/getUserProducts', async ({ categoryId, page, perPage, search }, { rejectWithValue }) => {
  try {
    const data = await getUserProductsApi(categoryId, page, perPage, search)
    if (data) {
      return data
    }
    throw new Error('Products not found')
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const buyProducts = createAsyncThunk('product/buyProducts', async (args, { rejectWithValue }) => {
  try {
    const response = await buyProductsApi(args)
    if (response?.status === 200 && response?.data?.is_success) {
      const { data } = response
      return data?.data?.message
    }
    throw new Error(response?.data?.data?.message)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const changeUserProductIp = createAsyncThunk('product/changeUserProductIp', async (args, { rejectWithValue }) => {
  try {
    const response = await changeUserProductIpApi(args)
    if (response?.is_success) {
      return response?.data.message
    }
    throw new Error(response)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getAllUserProducts = createAsyncThunk('product/getAllUserProducts', async (args, { rejectWithValue }) => {
  try {
    const response = await getAllUserProductsApi(args)
    if (response?.status === 200 && response?.data?.is_success) {
      const { data } = response
      return data?.data
    }
    throw new Error(response?.data?.data?.message)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getCalculateProducts = createAsyncThunk('product/getCalculateProducts', async (args, { rejectWithValue }) => {
  try {
    const response = await getUserProductsCalculateApi()
    if (response?.status === 200 && response?.data?.is_success) {
      const { data } = response
      return data?.data
    }
    throw new Error(response?.data?.data?.message)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getAllSell = createAsyncThunk('product/getAllSell', async (args, { rejectWithValue }) => {
  try {
    const response = await getAllSellApi(args)
    if (response?.status === 200 && response?.data?.is_success) {
      const { data } = response
      return data?.data
    }
    throw new Error(response?.data?.data?.message)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setIsloading: (state, action) => {
      state.isLoading = action.payload
    },
    clearError: (state, action) => {
      state.error = null
    },
    clearMessage: (state, action) => {
      state.message = null
      state.messageType = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action?.payload
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.isLoading = false
        state.product = {}
        state.error = action?.payload
      })
      .addCase(getCategories.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.categories = action?.payload
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isLoading = false
        state.categories = []
        state.error = action?.payload
      })
      .addCase(getProductByCategory.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getProductByCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.products = action?.payload?.data
        state.pagination = action?.payload?.pagination
      })
      .addCase(getProductByCategory.rejected, (state, action) => {
        state.isLoading = false
        state.products = []
        state.pagination = {}
        state.error = action?.payload
      })
      .addCase(getAllProducts.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.products = action?.payload?.data
        state.pagination = action?.payload?.pagination
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.isLoading = false
        state.products = []
        state.pagination = {}
        state.error = action?.payload
      })
      .addCase(getPopularProduct.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getPopularProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.products = action?.payload
      })
      .addCase(getPopularProduct.rejected, (state, action) => {
        state.isLoading = false
        state.products = []
        state.error = action?.payload
      })
      .addCase(getUserProducts.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getUserProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.products = action?.payload?.data
        state.pagination = action?.payload?.pagination
      })
      .addCase(getUserProducts.rejected, (state, action) => {
        state.isLoading = false
        state.products = []
        state.pagination = {}
        state.error = action?.payload
      })
      .addCase(buyProducts.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(buyProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.messageType = 'success'
        state.message = action.payload
      })
      .addCase(buyProducts.rejected, (state, action) => {
        state.isLoading = false
        state.error = action?.payload
      })
      .addCase(changeUserProductIp.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(changeUserProductIp.fulfilled, (state, action) => {
        state.isLoading = false
        state.messageType = 'success'
        state.message = action.payload
      })
      .addCase(changeUserProductIp.rejected, (state, action) => {
        state.isLoading = false
        state.error = action?.payload
      })
      .addCase(getAllUserProducts.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getAllUserProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.userProducts = action?.payload
      })
      .addCase(getAllUserProducts.rejected, (state, action) => {
        state.isLoading = false
        state.userProducts = []
        state.error = action?.payload
      })
      .addCase(getCalculateProducts.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getCalculateProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.userProducts = action?.payload
      })
      .addCase(getCalculateProducts.rejected, (state, action) => {
        state.isLoading = false
        state.userProducts = []
        state.error = action?.payload
      })
      .addCase(getAllSell.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getAllSell.fulfilled, (state, action) => {
        state.isLoading = false
        state.allSells = action?.payload
      })
      .addCase(getAllSell.rejected, (state, action) => {
        state.isLoading = false
        state.allSells = []
        state.error = action?.payload
      })
  },
})

export const { setIsloading, clearError, clearMessage } = productSlice.actions

export default productSlice.reducer
