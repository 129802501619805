export const columns = () => {
  return [
    {
      title: 'Cover',
      key: 'cover',
      render: (record) => <img src={record?.image_review} style={{ width: '50px' }} alt="cover" />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      sorter: (a, b) => a.total - b.total,
      defaultSortOrder: 'descend',
    },
  ]
}
