import React, { useState } from 'react'
import i18n from 'i18n'
import { useTranslation } from 'react-i18next'
import Menus from 'components/Menus'
import { Drawer, Col, Button, Collapse, Typography } from 'antd'
import { ShoppingFilled } from '@ant-design/icons'
import { MenuOutlined } from '@ant-design/icons'
import Style from './style.module.scss'
import Topup from 'assets/images/top-up.svg'
import DarkMode from 'assets/images/dark.png'
import LightMode from 'assets/images/light.png'
import { UserMenuPopOver } from 'components/UserMenuPopOver'
import { LoginPopover } from 'components/LoginPopover'
import Cart from 'components/Cart'
import Hamster from 'assets/images/hamster.jpg'
const { Panel } = Collapse
const { Text } = Typography

const MobileMenu = ({ onFinish, isLoggedIn, handleClickMenu, activeMenu, items, darkModeEnabled, handleTheme, handleLanguage, profile, isModalOpen, setIsModalOpen, cart }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  return (
    <Col xs={12} sm={18} md={0} lg={0} xl={0} className={Style.mobile_menu}>
      <Button type="primary" onClick={showDrawer} style={{ display: 'flex', alignItems: 'center' }}>
        <MenuOutlined />
      </Button>

      <Drawer title={t('general.menu')} placement="right" onClose={onClose} open={open} width="80%" bodyStyle={{ padding: '0px' }}>
        <div className={Style.mobile_config_menu}>
          <Button className={Style.menu_button} type="primary">
            <ShoppingFilled style={{ fontSize: '24px' }} onClick={() => setIsModalOpen(true)} />
            {cart.length > 0 && <p className={darkModeEnabled ? Style.cart_count_dark : Style.cart_count}>{cart.length}</p>}
          </Button>
          <Button
            className={Style.menu_button}
            type="primary"
            onClick={() => {
              window.open('https://discord.gg/jAeAauWtHc', '_blank')
            }}
          >
            <img src={Topup} className={darkModeEnabled ? Style.menu_icon_dark : Style.menu_icon} alt="topup" />
          </Button>
          <Button className={Style.menu_button} type="primary" onClick={handleTheme}>
            <img src={darkModeEnabled ? DarkMode : LightMode} className={darkModeEnabled ? Style.menu_icon_dark : Style.menu_icon} alt="mode" />
          </Button>
          <Button className={Style.menu_button} type="primary" onClick={handleLanguage}>
            <div style={{ width: '24px' }}>{i18n?.language?.toUpperCase()}</div>
          </Button>
        </div>

        <div style={{ padding: '10px' }}>
          <Menus onClick={handleClickMenu} selectedKeys={activeMenu} items={items} mode="vertical" style={{ borderRadius: '16px' }} />
          <Collapse defaultActiveKey={['1']} style={{ margin: '20px 0px' }} className={Style.collapse}>
            <Panel
              header={
                isLoggedIn ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={profile?.avatarUrl}
                      onError={(e) => {
                        e.target.src = Hamster
                      }}
                      className={Style.profile_picture}
                      alt="profile"
                    />
                    <Text>{profile?.username}</Text>
                  </div>
                ) : (
                  <Text>{t('menu.sign_in')}</Text>
                )
              }
              key="1"
            >
              {isLoggedIn ? <UserMenuPopOver profile={profile} /> : <LoginPopover onFinish={onFinish} />}
            </Panel>
          </Collapse>
        </div>
      </Drawer>
      <Cart isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </Col>
  )
}

export default MobileMenu
