import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Row, Col, Typography, Button, Modal, message } from 'antd'
import { buyProducts, getProductByCategory } from 'store/productSlice'
import { useDispatch } from 'react-redux'
import { HeartFilled, HeartOutlined } from '@ant-design/icons'
import Style from './style.module.scss'
import useIsLogin from 'hooks/useIsLogin'
import { likeProductApi } from 'api'
import useIsLike from 'hooks/useIsLike'
import { useLocation } from 'react-router-dom'
import QueryString from 'qs'
import DefaultProductImage from 'assets/images/product.png'

const { Title, Text } = Typography
const { confirm } = Modal

const ProductItem = ({ product, darkModeEnabled }) => {
  const { search } = useLocation()
  const qs = QueryString.parse(search, { ignoreQueryPrefix: true })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isShowReview, setIsShowReview] = useState(false)
  const isLoggedIn = useIsLogin()

  const handleClickProduct = () => {
    window.open(`/store/${product?.id}${product?.resource_name ? `-${product?.resource_name}` : ''}`, '_blank').focus()
  }
  const handleBuyItem = () => {
    dispatch(buyProducts({ products: [product?.id], redeem_code: '' }))
  }

  const openConfirmModal = (e) => {
    e.stopPropagation()
    if (!isLoggedIn) {
      message.error(t('error.please_login'))
      return
    }
    confirm({
      title: t('product.title_confirm'),
      content: t('product.content_confirm'),
      okText: t('general.confirm'),
      cancelText: t('general.cancel'),
      closable: true,
      okButtonProps: { className: Style.ok_button },
      cancelButtonProps: { className: Style.cancel_button },
      onOk: () => handleBuyItem(),
    })
  }
  const handleLike = async (event) => {
    event.stopPropagation()
    try {
      await likeProductApi(product.id, (result) => {
        if (result.isSuccess) {
          dispatch(getProductByCategory({ id: qs?.type, page: qs?.page, perPage: qs?.perPage, search: qs?.search }))
        } else {
          message.error(t('error.failed_to_connect'))
        }
      })
    } catch (error) {
      message.error(t('error.failed_to_connect'))
    }
  }

  const isLike = useIsLike(product?.user_likes ?? '[]')

  return (
    <Card
      hoverable
      className={Style.product_card}
      bodyStyle={{ padding: '14px' }}
      onMouseOver={() => {
        setIsShowReview(true)
      }}
      onMouseOut={() => {
        setIsShowReview(false)
      }}
      onClick={handleClickProduct}
    >
      <img
        src={isShowReview ? product?.image_review : product?.images_url}
        onError={(e) => {
          e.target.src = DefaultProductImage
        }}
        alt={product?.name}
        className={Style.cover_image}
      />
      <Row>
        <Col span={16}>
          <Title level={5} className={Style.product_name}>
            {product?.name}
          </Title>
        </Col>
        <Col span={8}>
          <Title level={5} className={product?.price === 0 ? Style.product_price_free : Style.product_price}>
            {`${product?.price} ${t('general.point')}`}
          </Title>
        </Col>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'inline' }}>
            {isLike ? (
              <HeartFilled className={darkModeEnabled ? Style.like_icon_dark : Style.like_icon} onClick={(e) => handleLike(e)} />
            ) : (
              <HeartOutlined className={darkModeEnabled ? Style.like_icon_dark : Style.like_icon} onClick={(e) => handleLike(e)} />
            )}
            <Text className={Style.like_count}>{product?.likes_amount}</Text>
          </div>
          <Button type="primary" style={{ fontSize: '13px' }} size="small" onClick={openConfirmModal}>
            {t('product.get_free')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default ProductItem
