/* eslint-disable */

import React, { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Image, Modal, Table, Typography, Button, Input, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import Style from './style.module.scss'
import { deleteItemById, clearCart } from 'store/cartSlice'
import { buyProducts } from 'store/productSlice'
import useIsLogin from 'hooks/useIsLogin'

const { Text } = Typography

const Cart = ({ isModalOpen, setIsModalOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { cart } = useSelector((state) => state.cart)
  const [redeemCode, setRedeemCode] = useState('')

  const inputMemo = useMemo(() => <Input prefix={t('cart.input_redeem_code')} onChange={(e) => setRedeemCode(e.target.value)} style={{ maxWidth: '50%' }} />, [redeemCode])
  const isLoggedIn = useIsLogin()
  const columns = [
    {
      title: t('cart.list'),
      key: 'list',
      render: (product) => (
        <div className={Style.product_name}>
          <Image src={product?.images_url} alt={product?.name} className={Style.product_img} />
          <Text>{product.name}</Text>
        </div>
      ),
    },
    {
      title: t('cart.subtotal'),
      align: 'right',
      key: 'subtotal',
      dataIndex: 'price',
    },
    {
      align: 'delete',
      key: 'delete',
      width: '50px',
      render: (product) => <DeleteOutlined onClick={() => dispatch(deleteItemById(product?.id))} />,
    },
  ]

  const handleBuy = () => {
    if (!isLoggedIn) {
      message.error(t('error.please_login'))
      return
    }
    if (cart?.length) {
      const payload = cart.map((item) => {
        return item?.id
      })
      dispatch(buyProducts({ products: payload, redeem_code: redeemCode }))
      dispatch(clearCart())
      setIsModalOpen(false)
    } else {
      message.error(t('cart.products_not_found'))
    }
  }
  return (
    <div>
      <Modal
        title={t('cart.name')}
        open={isModalOpen}
        onCancel={(val) => {
          setIsModalOpen(false)
        }}
        onOk={handleBuy}
        okText={t('cart.confirm_checkout')}
        cancelText={t('cart.close_cart')}
        width="600px"
        destroyOnClose
      >
        <div style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={() => dispatch(clearCart())}>
            {t('cart.clear_cart')}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={cart}
          pagination={false}
          style={{ borderRadius: '16px', margin: '20px 0px' }}
          summary={(pageData) => {
            let totalPrice = 0
            pageData.forEach(({ price }) => {
              totalPrice += price
            })
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>{t('cart.total_price')}</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} className={Style.total_price}>
                    <Text>{totalPrice}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }}
        />
        <div className={Style.redeem_container}>{inputMemo}</div>
      </Modal>
    </div>
  )
}

export default Cart
