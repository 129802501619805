/* eslint-disable */

import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Spin } from 'antd'
import ProductItem from './ProductItem'

const ProductList = () => {
  const { isLoading, products } = useSelector((state) => state.product)

  return isLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Spin size="large" />
    </div>
  ) : (
    <Row gutter={[20, 20]} justify="start" className="animate__animated animate__fadeIn">
      {products?.map((product, index) => (
        <Col xs={24} sm={8} md={8} lg={8} xl={6} key={product?.name + product?.id + index}>
          <ProductItem product={product} />
        </Col>
      ))}
    </Row>
  )
}

export default ProductList
