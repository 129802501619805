import React from 'react'
import { Modal, Input, Form, Row, Col, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { FORM_CONSTANT } from './constant'
import { changePassword } from 'store/userSlice'
import { useDispatch } from 'react-redux'
import Style from './style.module.scss'

const ChangePasswordModal = ({ isOpen, handleClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [changePassForm] = Form.useForm()
  return (
    <Modal open={isOpen} onCancel={handleClose} footer={null}>
      <Form
        layout="vertical"
        initialValues={{ oldPassword: '', newPassword: '', confirmNewPassword: '' }}
        form={changePassForm}
        onFinish={(val) => {
          let payload = val
          delete payload.confirmNewPassword
          dispatch(changePassword(payload))
          changePassForm.resetFields()
          handleClose()
        }}
      >
        <Row gutter={[20, 20]}>
          {FORM_CONSTANT(t).map((formInfo, index) => (
            <Col Col xs={24} sm={24} md={24} key={index}>
              {formInfo.name === 'confirmNewPassword' ? (
                <Form.Item
                  label={formInfo.label}
                  name={formInfo.name}
                  key={formInfo.name}
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: `${t('register.please_input_your')} ${formInfo.label}`,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'))
                      },
                    }),
                  ]}
                >
                  <Input type="password" disabled={formInfo.disable} />
                </Form.Item>
              ) : (
                <Form.Item
                  label={formInfo.label}
                  name={formInfo.name}
                  key={formInfo.name}
                  rules={[
                    {
                      required: formInfo.required,
                      message: `${t('register.please_input_your')} ${formInfo.label}`,
                    },
                  ]}
                >
                  <Input type={formInfo.type} disabled={formInfo.disable} />
                </Form.Item>
              )}
            </Col>
          ))}
          <Col xs={24} sm={24} className={Style.btn_container}>
            <Form.Item type="submit">
              <Button type="primary" htmlType="submit">
                {t('register.submit')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ChangePasswordModal
