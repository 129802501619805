/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from 'store/userSlice'
import { setActiveMenu } from 'store/menuSlice'
import { toggleDarkMode } from 'store/themeSlice'
import { Layout, Row, Col, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import Style from './style.module.scss'
import Logo from 'assets/images/logo.png'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'
const { Header } = Layout

const MenuBar = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const darkModeEnabled = useSelector((state) => state?.theme?.darkModeEnabled)
  const isLoggedIn = useSelector((state) => state?.user?.isLoggedIn)
  const profile = useSelector((state) => state?.user?.profile)
  const isLoading = useSelector((state) => state?.user?.isLoading)
  const activeMenu = useSelector((state) => state?.menu?.activeMenu)
  const { cart } = useSelector((state) => state?.cart)
  const [isScrolled, setIsScrolled] = useState(false)
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line
    storeMenuByLocation(location?.pathname + location?.search ?? '')
  }, [location])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset

      // Check if the user has scrolled down or reached the top of the page
      setIsScrolled(scrollTop > 0)
      setScroll(scrollTop)
    }

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const storeMenuByLocation = (key) => {
    dispatch(setActiveMenu(key))
  }

  const handleClickMenu = (item) => {
    navigate(item?.key ?? '/')
  }

  const handleTheme = () => {
    dispatch(toggleDarkMode())
    localStorage.setItem('darkModeEnabled', JSON.stringify(!darkModeEnabled))
  }

  const handleLanguage = () => {
    if (i18n.language === 'th') {
      i18n.changeLanguage('en')
      localStorage.setItem('language', 'en')
    } else {
      i18n.changeLanguage('th')
      localStorage.setItem('language', 'th')
    }
  }
  const items = [
    {
      label: t('menu.home'),
      key: '/',
    },
    {
      label: t('menu.store'),
      key: '/store?type=0&page=1&perPage=8',
    },
    {
      label: t('menu.free'),
      key: '/free?type=4&page=1&perPage=8',
    },
    {
      label: t('menu.commission'),
      key: '/commission',
    },
  ]

  const onFinish = async (credentials) => {
    dispatch(login(credentials))
  }
  return (
    <Header
      className={darkModeEnabled ? Style.header_dark : Style.header_default}
      style={isScrolled ? { opacity: '0.5' } : { opacity: '1' }}
      onMouseOver={() => setIsScrolled(false)}
      onMouseOut={() => {
        if (scroll > 0) {
          setIsScrolled(true)
        }
      }}
    >
      <Row justify="space-between" style={{ height: '100%' }}>
        <Col xs={12} sm={6} className={Style.logo_container}>
          <img src={Logo} alt="MMT_LOGO" style={{ maxHeight: '50px', cursor: 'pointer' }} className="animate__animated animate__jackInTheBox" onClick={() => navigate('/')} />
        </Col>
        {isLoading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <>
            <DesktopMenu
              onFinish={onFinish}
              isLoggedIn={isLoggedIn}
              handleClickMenu={handleClickMenu}
              activeMenu={activeMenu}
              items={items}
              darkModeEnabled={darkModeEnabled}
              handleTheme={handleTheme}
              handleLanguage={handleLanguage}
              profile={profile}
              isModalOpen={isModalOpen}
              setIsModalOpen={(status) => setIsModalOpen(status)}
              cart={cart}
            />
            <MobileMenu
              onFinish={onFinish}
              isLoggedIn={isLoggedIn}
              handleClickMenu={handleClickMenu}
              activeMenu={activeMenu}
              items={items}
              darkModeEnabled={darkModeEnabled}
              handleTheme={handleTheme}
              handleLanguage={handleLanguage}
              profile={profile}
              isModalOpen={isModalOpen}
              setIsModalOpen={(status) => setIsModalOpen(status)}
              cart={cart}
            />
          </>
        )}
      </Row>
    </Header>
  )
}

export default MenuBar
