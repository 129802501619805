/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProduct } from 'store/productSlice'
import { useTranslation } from 'react-i18next'
import DefaultLayout from 'layouts/DefaultLayout'
import { Row, Col, message } from 'antd'
import ProductPreview from './components/ProductPreview'
import Style from './style.module.scss'
import ProductMainInfo from './components/ProductMainInfo'
import ProductDetails from './components/ProductDetails'

const Product = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const productState = useSelector((state) => state.product)
  const darkModeEnabled = useSelector((state) => state.theme.darkModeEnabled)
  const [selectedProduct, setSelectedProduct] = useState(0)
  const [mainId, setMainId] = useState(0)
  const { isLoading, product, error } = productState

  useEffect(() => {
    const formattedId = parseInt(id.split('-')[0])
    dispatch(getProduct(formattedId))
    setMainId(formattedId)
  }, [id])

  useEffect(() => {
    if (product?.length > 0) {
      if (selectedProduct !== 0) {
        setSelectedProduct((prev) => product?.find((item) => item?.id === prev?.id))
      } else {
        setSelectedProduct(product[0])
      }
    }
  }, [product])

  useEffect(() => {
    if (error) {
      message.error(error ?? t('product.not_found'))
    }
  }, [error])

  return error ? (
    <Navigate to="/" replace={true} />
  ) : (
    <DefaultLayout isLoading={isLoading}>
      <Row gutter={[20, 30]}>
        <ProductPreview product={selectedProduct} />
        <Col xs={24} sm={24} md={12}>
          <ProductMainInfo product={selectedProduct} setSelectedProduct={setSelectedProduct} productList={product} darkModeEnabled={darkModeEnabled} mainId={mainId} />
        </Col>
        <Col span={24} className={darkModeEnabled ? Style.detail_container_dark : Style.detail_container}>
          <ProductDetails product={selectedProduct} darkModeEnabled={darkModeEnabled} />
        </Col>
      </Row>
    </DefaultLayout>
  )
}

export default Product
