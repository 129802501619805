import React from 'react'
import YoutubeVideo from 'components/YoutubeVideo'
import { Col, Image } from 'antd'
import Style from './style.module.scss'
const ProductPreview = ({ product }) => {
  return (
    <>
      {product?.categories_id === 1 ? (
        <Col xs={24} sm={24} md={12}>
          {product?.video_link ? <YoutubeVideo src={`https://www.youtube.com/embed/${product?.video_link}`} scriptName={product?.name} /> : <Image src={product?.images_url} style={{ width: '100%' }} />}
          <div className={Style.images_container}>{product?.video_link && <Image className={Style.image} src={product?.images_url} style={{ width: '100%' }} />}</div>
        </Col>
      ) : (
        <Col style={{ width: '580px', display: 'flex', justifyContent: 'center' }}>
          <Image src={product?.images_url} style={{ width: '100%', maxHeight: '340px', objectFit: 'cover' }} />
        </Col>
      )}
    </>
  )
}

export default ProductPreview
