import axios from 'utils/axios-instance'
import { headerConfigs } from 'utils'
const BASE_URL = process.env.REACT_APP_API_URL

export const loginApi = async (credentials) => {
  const response = await axios.post(`${BASE_URL}/users/login`, credentials, headerConfigs())
  if (response?.status === 200 && response?.data?.is_success) {
    const { data } = response
    localStorage.setItem('AUTH_TOKEN', data?.data?.jwt)
    return { is_success: data.is_success, jwt: data?.data?.jwt }
  }
  return new Error(response?.data?.data?.message)
}

export const updateProfileApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/users/profile`, payload, headerConfigs())
  return response
}

export const changePasswordApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/users/change-password`, payload, headerConfigs())
  return response
}

export const getProfileApi = async () => {
  const response = await axios.get(`${BASE_URL}/users/profile`, headerConfigs())
  if (response?.status === 200 && response?.data?.is_success) {
    const { data } = response
    return data?.data
  }
  return new Error('error')
}

export const forgotPasswordApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/users/reset-password`, payload, headerConfigs())
  if (response?.status === 200 && response?.data?.is_success) {
    const { data } = response
    return data?.data
  }
  return new Error('error')
}

export const registerApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/users/register`, payload, headerConfigs())
  return response
}

export const logoutApi = async () => {
  const response = await axios.post(`${BASE_URL}/users/logout`, null, headerConfigs())
  if (response?.status === 200 && response?.data?.is_success) {
    localStorage.removeItem('AUTH_TOKEN')
    window.location.replace('/')
    const { data } = response
    return data?.data?.message
  }
  return new Error('error')
}

export const getDiscordInfo = async (code, redirectUrl) => {
  const response = await axios.post(`${BASE_URL}/users/discord`, { code, redirectUrl })
  return response
}

export const getAllUserApi = async () => {
  const response = await axios.get(`${BASE_URL}/users/get-all-users`, headerConfigs())
  return response
}

export const editUserApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/users/edit`, data, headerConfigs())
  return response
}

export const getCreditLogsApi = async (type) => {
  const response = await axios.get(`${BASE_URL}/users/credit-logs?type=${type}`, headerConfigs())
  return response
}

export const addPointsApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/users/point/add`, data, headerConfigs())
  return response
}

export const resetPasswordApi = async (token, password) => {
  const response = await axios.post(`${BASE_URL}/users/reset-password/${token}`, password, headerConfigs())
  return response
}
