/* eslint-disable */
import DefaultLayout from 'layouts/DefaultLayout'
import React from 'react'
import { List } from 'antd'
import { TERMS } from './constant'

const Terms = () => {
  const dataSource = TERMS?.split('+').slice(1, -1)
  return (
    <DefaultLayout>
      <List size="large" header={<div>ข้อกำหนดและเงื่อนไข</div>} bordered dataSource={dataSource} renderItem={(item) => <List.Item>{item}</List.Item>} />
    </DefaultLayout>
  )
}

export default Terms
