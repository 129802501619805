/* eslint-disable */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Row, Col, Typography, Spin, Button } from 'antd'
import style from './style.module.scss'
import Decoration from 'assets/images/3D_elements.png'
import { addItemToCart } from 'store/cartSlice'
const { Title, Text } = Typography

const HeaderSection = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { products, isloading } = useSelector((state) => state.product)

  const getTopProduct = () => {
    return products?.[0]
  }
  const handleAddItemToCart = (event) => {
    event.stopPropagation()
    dispatch(addItemToCart(products?.[0]))
  }

  return (
    <div className={style.wrapper}>
      {isloading ? (
        <Spin />
      ) : (
        products?.length > 0 && (
          <Row style={{ height: '100%' }}>
            <Col xs={24} lg={10} className={`animate__animated animate__bounceInDown animate__slow ${style.left_box}`}>
              <Title level={1} style={{ fontSize: '48px' }}>
                {getTopProduct().name}
              </Title>
              <Text style={{ fontSize: '18px' }}>{`${t('product.price')}: ${getTopProduct().price} ${t('product.baht')}`}</Text>
              <div>
                <Link to={`/store/${getTopProduct().id}`}>
                  <Button style={{ margin: '16px 4px' }}>{t('general.view_more')}</Button>
                </Link>
                <Button type="primary" style={{ margin: '16px 4px' }} onClick={handleAddItemToCart}>
                  {t('product.add_to_cart')}
                </Button>
              </div>
            </Col>
            <Col xs={24} lg={14} className={`animate__animated animate__bounceInUp animate__slow ${style.img_container}`}>
              <img src={Decoration} className={style.img_decoration} />
              <img src={getTopProduct().image_review} className={style.img_banner} />
            </Col>
          </Row>
        )
      )}
    </div>
  )
}

export default HeaderSection
