import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from 'layouts/DefaultLayout'
import { Row, Col, Typography, Input, message as NotiMessage, Button, Card, Select, Checkbox } from 'antd'
import Style from './style.module.scss'
import { OPTIONS, TARGET_VERSION } from './constant'
import { getObfuscateApi } from 'api'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const { Title, Text } = Typography

const Obfuscate = () => {
  const { t } = useTranslation(null)
  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState({
    ivm: false,
    egf: false,
    vm: false,
    dli: false,
    udl: false,
    target: 'Universal',
  })
  const { profile } = useSelector((state) => state.user)

  useEffect(() => {
    if (![profile?.isAdmin, profile?.isOwner, profile?.isObfuscator].includes(true)) {
      navigate('/')
    }
  }, [profile, navigate])

  const handleSetOptions = (value, name) => {
    setOptions((prev) => ({ ...prev, [name]: value }))
  }

  const generateCode = async () => {
    setIsLoading(true)
    try {
      const payload = {
        code,
        options,
      }
      const response = await getObfuscateApi(payload)
      const { data } = response
      if (data?.is_success === true) {
        NotiMessage.success(t('general.success'))
        setCode(data?.data?.code)
      } else {
        NotiMessage.error(data.data.message)
      }
    } catch (error) {
      NotiMessage.error(error?.response?.status === 429 ? error?.response?.data : error?.response?.data?.message)
    }
    setIsLoading(false)
  }

  return (
    <DefaultLayout>
      <Card title={<Title>{t('obfuscate.title')}</Title>}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} className={Style.container}>
            <Input.TextArea className={Style.obfuscate_text} value={code} onChange={(e) => setCode(e.target.value)} />
          </Col>
          <Col span={24}>
            <Text>{t('obfuscate.target_version')}: </Text>
            <Select
              value={options.target}
              style={{
                width: 200,
              }}
              options={TARGET_VERSION}
              onChange={(val) => handleSetOptions(val, 'target')}
            />
          </Col>
          <Col span={24}>
            <Text>{t('obfuscate.options')}: </Text>
            {OPTIONS.map((item) => (
              <Checkbox onChange={(e) => handleSetOptions(e.target.checked, item.fieldName)} key={item.fieldName}>
                {item.label}
              </Checkbox>
            ))}
          </Col>

          <Col xs={24} sm={24} className={Style.btn_container}>
            <Button type="primary" onClick={generateCode} loading={isLoading}>
              {t('general.submit')}
            </Button>
          </Col>
        </Row>
      </Card>
    </DefaultLayout>
  )
}

export default Obfuscate
