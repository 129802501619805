import { Button, Form, Input, message, Modal, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { columns } from './constant'
import { getAllUser, getCreditLogs } from 'store/userSlice'
import { addPointsApi } from 'api'

const AddPoint = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { creditLogs, users, isLoading } = useSelector((state) => state.user)
  const [filteredData, setFilteredData] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [currentPageSize, setCurrentPageSize] = useState(6)
  const [isOpen, setIsOpen] = useState(false)
  const [userInfo, setUserInfo] = useState(false)
  const [userOptions, setOptions] = useState([])
  useEffect(() => {
    dispatch(getCreditLogs(1))
    dispatch(getAllUser())
  }, [dispatch])

  const handleSearch = () => {
    if (searchText) {
      const filtered = creditLogs?.filter((item) => item?.username.includes(searchText))
      setFilteredData(filtered)
    } else {
      setFilteredData(false)
    }
  }

  useEffect(() => {
    if (isOpen && userInfo) {
      form.setFieldsValue(userInfo)
    }
  }, [isOpen, userInfo, form])

  useEffect(() => {
    if (users) {
      const filtered = users?.map((item) => {
        return { value: item?.username, label: item?.username, key: item?.username }
      })
      setOptions(filtered)
    }
  }, [users])

  const addPoints = async (paylod) => {
    const response = await addPointsApi(paylod)
    const { data } = response
    if (response?.status === 200 && data?.is_success === true) {
      message.success(data?.data?.message)
      dispatch(getCreditLogs(1))
      dispatch(getAllUser())
    } else {
      message.error(data?.data?.message)
    }
  }

  return (
    <div>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        Add Point
      </Button>
      <Select
        defaultValue={1}
        style={{
          width: 120,
          margin: '0px 10px',
        }}
        allowClear
        options={[
          {
            value: 1,
            label: 'เติมพอยท์',
          },
          {
            value: 0,
            label: 'หักพอยท์',
          },
        ]}
        onSelect={(val) => {
          dispatch(getCreditLogs(val))
        }}
      />
      <Input.Search style={{ padding: '20px' }} onChange={(e) => setSearchText(e.target.value)} onPressEnter={handleSearch} onSearch={handleSearch} placeholder="ค้นหาด้วย Username" />
      <Table
        columns={columns(setUserInfo)}
        dataSource={filteredData !== false ? filteredData : creditLogs}
        loading={isLoading}
        pagination={{ pageSize: currentPageSize }}
        onChange={(page) => setCurrentPageSize(page?.pageSize)}
        scroll={{ x: 'max-content' }}
      />
      <Modal open={isOpen} onCancel={() => setIsOpen(false)} footer={null}>
        <Form
          layout="vertical"
          initialValues={{ username: '', amount: '', reason: '' }}
          form={form}
          onFinish={(val) => {
            addPoints(val)
            setIsOpen(false)
          }}
        >
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: `กรุณาเลือก User`,
              },
            ]}
          >
            <Select allowClear showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={userOptions} />
          </Form.Item>

          <Form.Item
            name="amount"
            label="จำนวน"
            rules={[
              {
                required: true,
                message: `กรุณากรอกจำนวน`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="reason"
            label="เหตุผล"
            rules={[
              {
                required: true,
                message: `กรุณากรอกเหตุผล`,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit" block style={{ marginBottom: '30px' }}>
            บันทึกข้อมูล
          </Button>
        </Form>
      </Modal>
    </div>
  )
}

export default AddPoint
