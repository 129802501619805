/* eslint-disable */

import React, { useEffect, useState } from 'react'
import DefaultLayout from 'layouts/DefaultLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories, getUserProducts } from 'store/productSlice'
import { Tabs, Pagination } from 'antd'
import PurchaseList from './PurchaseList'
import QueryString from 'qs'

const Purchases = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const qs = QueryString.parse(search, { ignoreQueryPrefix: true })
  const navigate = useNavigate()
  const productState = useSelector((state) => state.product)
  const { categories, pagination } = productState
  const [items, setItem] = useState([])
  const [activeKey, setActiveKey] = useState('0')

  useEffect(() => {
    dispatch(getCategories())
  }, [])

  useEffect(() => {
    if (categories?.length > 0) {
      handleItems()
    }
  }, [categories])

  useEffect(() => {
    if (search) {
      handleSearchUrl()
    }
  }, [search])

  const handleItems = () => {
    const toShowCategories = categories.filter((item) => item.name !== 'FREE')
    const mapItem = toShowCategories?.map((category) => {
      return { key: String(category?.id), label: category?.label, children: <PurchaseList triggerUpdateData={triggerUpdateData} /> }
    })
    mapItem.unshift({ key: '0', label: 'All', children: <PurchaseList triggerUpdateData={triggerUpdateData} /> })
    setItem(mapItem)
  }

  const getProductByKey = () => {
    dispatch(getUserProducts({ categoryId: qs?.type, page: qs?.page, perPage: qs?.perPage, search: qs?.search }))
  }

  const handleSearchUrl = () => {
    if (!isNaN(qs?.type)) {
      getProductByKey(qs?.type)
      setActiveKey(qs?.type)
    }
  }

  const triggerUpdateData = () => {
    dispatch(getUserProducts({ categoryId: qs?.type, page: qs?.page, perPage: qs?.perPage, search: qs?.search }))
  }

  const handleSetPage = (page) => {
    const query = QueryString.stringify({ ...qs, page })
    navigate(`/purchases?${query}`)
  }

  return (
    <DefaultLayout>
      <Tabs
        activeKey={activeKey}
        items={items}
        onChange={(val) => {
          navigate(`/purchases?${QueryString.stringify({ ...qs, type: val, page: 1 })}`)
        }}
        centered
        destroyInactiveTabPane
      />
      <Pagination
        current={pagination?.page ?? 1}
        pageSize={pagination?.perPage ?? 10}
        total={pagination?.totalItems ?? 10}
        onChange={(page) => handleSetPage(page)}
        style={{ margin: '20px 10px', display: 'flex', justifyContent: 'flex-end' }}
        showSizeChanger={false}
      />
    </DefaultLayout>
  )
}

export default Purchases
