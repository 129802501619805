import React from 'react'
import Style from './style.module.scss'
import { Row, Col, Form, Input, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const LoginPopover = ({ onFinish }) => {
  const { t } = useTranslation()
  return (
    <div className={Style.login_content}>
      <Row>
        <Col span={24}>
          <Form
            name="login"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label={t('general.username')}
              name="username"
              rules={[
                {
                  required: true,
                  message: t('warning.please_input_username'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('general.password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('warning.please_input_password'),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit" block>
                {t('general.login')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Link to={'/forgot-password'}>
                <Button>{t('general.forgot_password')}</Button>
              </Link>
            </Col>
            <Col span={12} style={{ textAlign: 'end' }}>
              <Link to={'/register'}>
                <Button>{t('general.register')}</Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
