/* eslint-disable */

import React from 'react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from 'layouts/DefaultLayout'
import { Row, Col, Typography, Form, Input, message as NotiMessage, Button, Card } from 'antd'
import Style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import QueryString from 'qs'
import { resetPasswordApi } from 'api'

const { Title } = Typography

const ConfirmPassword = () => {
  const [form] = Form.useForm()
  const { search } = useLocation()
  const navigate = useNavigate()
  const qs = QueryString.parse(search, { ignoreQueryPrefix: true })
  const { t } = useTranslation(null)

  const resetPassword = async (values) => {
    if (qs?.token) {
      try {
        const response = await resetPasswordApi(qs?.token, values)
        if (response?.status === 200 && response?.data?.is_success) {
          NotiMessage.success(response?.data?.data?.message)
        } else {
          NotiMessage.error(response?.data?.data?.message)
        }
        navigate('/')
      } catch (error) {
        NotiMessage.error(error?.message)
        navigate('/')
      }
    } else {
      navigate('/')
    }
  }

  return (
    <DefaultLayout>
      <Card title={<Title>{t('forgot_password.reset_pass')}</Title>}>
        <Form layout="vertical" form={form} onFinish={(val) => resetPassword(val)}>
          <Row justify={'center'}>
            <Col Col xs={24} sm={24} md={24} style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '500px' }}>
              <Form.Item
                label={t('forgot_password.password')}
                name={'password'}
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: `${t('register.please_input_your')} Password`,
                  },
                ]}
              >
                <Input type="password" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} className={Style.btn_container}>
              <Form.Item type="submit">
                <Button type="primary" htmlType="submit">
                  {t('forgot_password.confirm')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </DefaultLayout>
  )
}

export default ConfirmPassword
