export const TERMS = `
+การแก้ไขข้อมูลส่วนบุคคลจะต้อง ใช้บัตรประชาชนยืนยันตัวตน  เพื่อแก้ไขข้อมูล ไม่ว่าจะเป็น การเปลี่ยนแปลง Email แะละ  Discord

+ชื่อ และ นามสกุลของบัตรประชาชน จะต้องตรงกันกับที่ได้ ลงทะเบียนไว้  (ต้องจำเป็นจริงๆ)

+การซื้อของภายในร้าน Mamuang 1 การซื้อขายเป็นการซื้อสำหรับการนำไปใช้ในการทำเซิฟเวอร์ FiveM 1 เซิฟเวอร์เท่านั้นซึ่งผู้ซื้อไม่ใช่เจ้าของผลงาน Script และ Model ของทางร้าน

+Scripts Props และสินค้าใดๆก็ตามที่ทางร้านเปิดจำหน่าย ห้ามมีการซื้อ-ขาย-เช่า ฯลฯ สคริปต์ของทางร้าน Mamuang เด็ดขาด !! รวมไปถึงการโพสต์ การประกาศขาย หรือประกาศโอนย้ายสิทธิ ฯลฯ ต่อจากเจ้าของเดิม หรือ ห้ามเปลี่ยนมือผู้ครอบครองสิทธิทุกกรณี ไม่ว่าจะเป็น การเซ้งประเทศ หรือให้บุคคลอื่นที่ไม่ใช่เจ้าของสิทธิแต่ดั่งเดิมเข้ามาทำกิจการต่อ รวมไปถึงการขายหรือการให้ยก Discord และยกเลิกการโอนย้ายสิทธิทุกกรณี หากผิดเงื่อนไข ปลดยศและยุติการซัพพอร์ตทันที !!

+ไม่ว่าจะกรณีใดๆก็ตาม หลังจากวันที่ท่านได้ทำการซื้อสินค้าภายในร้าน Mamuang ไปแล้ว และมีการให้ยศแก่ลูกค้าเป็นที่เรียบร้อยแล้วทางร้านจะไม่มีการโอนยศไปให้แก่บุคคลอื่นเป็นอันขาด เช่น วันที่มาติดต่อซื้อสินค้าของทางร้าน ท่านให้ Dev หรือหุ้นส่วน หรือทีมงาน ฯลฯ เป็นผู้มาติดต่อซื้อสินค้าของทางร้าน แล้ววันหนึ่งเลิกทำ หรือมีปัญหาทะเลาะกันภายใน ทางเราจะไม่สนใจว่าใครเป็นเจ้าของบัญชีที่โอนเงินเข้ามาให้แก่ทางร้าน ทางเราจะยึดสิทธิตามลูกค้าตามยศของทางร้านเราเท่านั้น !!

+หากกรณีที่มีประกาศต่างๆของทางร้าน เช่น การโอนย้ายสิทธิ การยืนยันตัวตน ฯลฯ แล้วลูกค้าไม่มาแสดงตัวตนหรือทำตามที่ทางร้านประกาศในระยะเวลาที่กำหนด หรือเงื่อนไขต่างๆที่ทางเราได้ประกาศแจ้งไป เหตุการณ์ดังกล่าวนี้ทางเราจะถือว่าลูกค้าไม่รักษาสิทธิของตนเองและจะไม่มีการผ่อนผันกฎหรือเงื่อนไขต่างๆให้ลูกค้าทุกกรณี ลูกค้าจะไม่สามารถเรียกร้องสิทธิย้อนหลังได้


+กรณีที่ลูกค้าลืม Password หรือบัญชีที่ใช้ในการสมัครบนเว็บ รวมไปถึง Discord เช่น การลืมรหัส Discord หรือ Discord ติดไวรัสหรือใช้งานไม่ได้ ทางเราจะไม่มีการโอนย้ายหรือเปลี่ยนแปลงข้อมูลใดใดให้แก่ลูกค้าเป็นอันขาดทุกกรณี ดังนั้นรบกวนลูกค้าดูแลข้อมูลส่วนตัวของท่านให้เป็นอย่างดี เพื่อที่จะได้ไม่เกิดปัญหาดังตัวอย่างนี้ในอนาคต

+เมื่อเติมเงินแล้ว จะไม่มีการขอคืนเงิน หรือ เปลี่ยนกลับมาเป็นเงินสด ทุกกรณี`
