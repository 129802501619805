/* eslint-disable */

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getPopularProduct } from 'store/productSlice'
import DefaultLayout from 'layouts/DefaultLayout'
import HeaderSection from './HeaderSection'
import ProductList from './ProductList'

const Home = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPopularProduct())
  }, [])

  return (
    <DefaultLayout>
      <HeaderSection />
      <ProductList />
    </DefaultLayout>
  )
}

export default Home
