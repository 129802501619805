import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

const useIsLike = (usersLike) => {
  const { profile } = useSelector((state) => state.user)
  const formatUsersLike = JSON.parse(usersLike)
  const [isLike, setIsLike] = useState(false)

  const handleCheck = useCallback(() => {
    const isUserLike = formatUsersLike?.some((item) => {
      return item === profile.id
    })
    setIsLike(isUserLike)
  }, [formatUsersLike, profile.id])

  useEffect(() => {
    handleCheck()
  }, [usersLike, handleCheck])

  return isLike
}

export default useIsLike
