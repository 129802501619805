/* eslint-disable */

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DefaultLayout from 'layouts/DefaultLayout'
import { Row, Col, Typography, Form, Input, message as NotiMessage, Button, Card } from 'antd'
import Style from './style.module.scss'
import { clearMessage, forgotPassword } from 'store/userSlice'

const { Title } = Typography

const ForgotPassword = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { t } = useTranslation(null)
  const { message, isLoading } = useSelector((state) => state.user)

  useEffect(() => {
    if (message) {
      NotiMessage.success(message)
      dispatch(clearMessage())
    }
  }, [message, dispatch])

  return (
    <DefaultLayout>
      <Card title={<Title>{t('forgot_password.name')}</Title>} loading={isLoading}>
        <Form layout="vertical" form={form} onFinish={(val) => dispatch(forgotPassword(val))}>
          <Row justify={'center'}>
            <Col Col xs={24} sm={24} md={24} style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '500px' }}>
              <Form.Item label={t('forgot_password.form_name')} name={'emailOrUsername'} required style={{ width: '100%' }}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} className={Style.btn_container}>
              <Form.Item type="submit">
                <Button type="primary" htmlType="submit">
                  {t('forgot_password.confirm')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </DefaultLayout>
  )
}

export default ForgotPassword
