import moment from 'moment'
import DefaultProductImage from 'assets/images/product.png'
import Style from './style.module.scss'

export const columns = () => {
  return [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Server Name',
      dataIndex: 'fivem_name_server',
      key: 'fivem_name_server',
    },
    {
      title: 'Key Name',
      dataIndex: 'fivem_username',
      key: 'fivem_username',
    },
    {
      title: 'Order',
      key: 'order',
      render: (record) => (
        <div className={Style.order}>
          <img
            src={record?.images_url}
            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
            alt="edit product"
            onError={(e) => {
              e.target.src = DefaultProductImage
            }}
          />
          <p>{record?.name}</p>
        </div>
      ),
    },
    {
      title: 'Date',
      key: 'created_at',
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (record) => moment(record.created_at).format('DD/MM/YYYY - HH:MM'),
    },
    {
      title: 'Confirm',
      key: 'edit',
      render: (record) => <p>จัดส่งไฟล์แล้ว</p>,
    },
  ]
}
