import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import 'animate.css/animate.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { Provider } from 'react-redux'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
 .slick-list{
  height: 500px !important;
 }
`
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyles />
      <App />
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
