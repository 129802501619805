/* eslint-disable */

import React, { useEffect } from 'react'
import DefaultLayout from 'layouts/DefaultLayout'
import { Pagination } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProductByCategory } from 'store/productSlice'
import ProductList from './ProductList'
import QueryString from 'qs'

const Free = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const qs = QueryString.parse(search, { ignoreQueryPrefix: true })
  const { pagination } = useSelector((state) => state.product)
  const handleSetPage = (page) => {
    const query = QueryString.stringify({ ...qs, page })
    navigate(`/free?${query}`)
  }

  useEffect(() => {
    dispatch(getProductByCategory({ id: qs?.type, page: qs?.page, perPage: qs?.perPage, search: qs?.search }))
  }, [search])

  return (
    <DefaultLayout>
      <ProductList />
      <Pagination
        current={pagination?.page ?? 1}
        pageSize={pagination?.perPage ?? 10}
        total={pagination?.totalItems ?? 10}
        onChange={(page) => handleSetPage(page)}
        style={{ margin: '20px 10px', display: 'flex', justifyContent: 'flex-end' }}
      />
    </DefaultLayout>
  )
}

export default Free
