export const defaultTheme = {
  token: {
    fontFamily: 'Kanit',
    colorPrimaryBg: '#ffe33d',
    colorPrimary: '#FFC524',
    colorPrimaryActive: '#FFC524',
    colorPrimaryBgHover: '#ffdd33',
    colorPrimaryBorder: '#FFC524',
    colorPrimaryBorderHover: '#d4b000',
    colorPrimaryHover: '#ffd900',
    colorPrimaryTextHover: '#4F5372',
    colorPrimaryText: '#4F5372',
    colorPrimaryTextActive: '#4F5372',
    colorWarningTextActive: '#d46306',
    colorWarningText: '#fa9714',
    borderRadius: 30,
    wireframe: false,
    colorInfoText: '#4F5372',
    colorInfoTextActive: '#4F5372',
    colorInfoTextHover: '#4F5372',
    colorInfoActive: '#4F5372',
    colorInfoHover: '#4F5372',
    colorInfo: '#dcaa22',
    colorTextBase: '#0f2443',
    colorBgBase: '#ebf0f0',
  }, 
  components: {
    Card: {
      colorBorderSecondary: '#ffc524',
    },
  },
}
export const darkTheme = {
  token: {
    fontFamily: 'Kanit',
    colorPrimaryBg: '#ffe33d',
    colorPrimary: '#FFC524',
    colorPrimaryActive: '#FFC524',
    colorPrimaryBgHover: '#ffdd33',
    colorPrimaryBorder: '#FFC524',
    colorPrimaryBorderHover: '#d4b000',
    colorPrimaryHover: '#ffd900',
    colorPrimaryTextHover: '#4F5372',
    colorPrimaryText: '#4F5372',
    colorPrimaryTextActive: '#4F5372',
    colorWarningTextActive: '#d46306',
    colorWarningText: '#fa9714',
    borderRadius: 30,
    wireframe: false,
    colorInfoText: '#4F5372',
    colorInfoTextActive: '#4F5372',
    colorInfoTextHover: '#4F5372',
    colorInfoActive: '#4F5372',
    colorInfoHover: '#4F5372',
    colorInfo: '#dcaa22',
    colorBgBase: '#0f2443',
    colorTextBase: '#f0f0f0',
  },
  components: {
    Button: {
      colorBgTextActive: '#ffc524',
      colorBgTextHover: '#ffc524',
      colorText: '#ededed',
      colorTextLightSolid: '#071429',
      colorLink: '#ffc524',
      colorLinkActive: '#ffc524',
      colorLinkHover: '#ffc524',
      colorBorder: '#ededed',
    },
    Select: {
      colorText: '#ededed',
      colorTextDescription: 'rgba(240, 240, 240, 0.45)',
      colorBgContainer: '#081529',
      colorBgElevated: '#0f2443',
    },
    Menu: {
      colorItemBg: '#071429',
      colorPrimary: '#ffc524',
      colorPrimaryBorder: '#FFC524',
      controlItemBgActive: '#ffe33d',
    },
  },
}
