import React from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import Style from './style.module.scss'
const { Title } = Typography

const ProductDetails = ({ product, darkModeEnabled }) => {
  const { t } = useTranslation()

  return (
    <div className={Style.detail_container}>
      <Title level={3} className={Style.detail_header}>
        {t('product.details')}
      </Title>
      <div className={darkModeEnabled ? Style.detail_dark : Style.detail} dangerouslySetInnerHTML={{ __html: product?.detail }}></div>
    </div>
  )
}

export default ProductDetails
