export const TARGET_VERSION = [
  { label: 'Universal', value: 'Universal' },
  { label: 'Lua 5.1', value: 'Lua 5.1' },
  { label: 'Lua 5.2', value: 'Lua 5.2' },
  { label: 'Lua 5.3', value: 'Lua 5.3' },
  { label: 'Lua 5.4', value: 'Lua 5.4' },
  { label: 'Luau', value: 'Luau' },
  { label: 'Luau Handicapped', value: 'Luau Handicapped' },
  { label: 'LuaJIT', value: 'LuaJIT' },
  { label: 'FiveM', value: 'FiveM' },
  { label: 'CS:GO', value: 'CS:GO' },
  { label: 'GMod', value: 'GMod' },
  { label: 'World of Warcraft', value: 'World of Warcraft' },
]
export const OPTIONS = [
  { label: 'Intense VM Structure', fieldName: 'ivm' },
  { label: 'Enable GC Fixes', fieldName: 'egf' },
  { label: 'VM Encryption', fieldName: 'vm' },
  { label: 'Disable Line Information', fieldName: 'dli' },
  { label: 'Use Debug Library', fieldName: 'udl' },
]
