/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DefaultLayout from 'layouts/DefaultLayout'
import { Row, Col, Typography, Form, Input, Image as ImageAntd, message as NotiMessage, Button, Card, Checkbox } from 'antd'
import Style from './style.module.scss'
import { FORM_CONSTANT, TERMS } from './constant'
import { Link, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import { getDiscordInfo } from 'api'
import DiscordLogo from 'assets/images/discord.png'
import { clearMessage, login, register } from 'store/userSlice'
import { isEmpty } from 'lodash'
const { Title } = Typography

const Register = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { search } = useLocation()
  const qs = QueryString.parse(search, { ignoreQueryPrefix: true })
  const { t } = useTranslation(null)
  const [profile, setProfile] = useState({})
  const [isAccept, setIsAccept] = useState(false)
  const { message, error, isLoading, isLoggedIn } = useSelector((state) => state.user)
  const englishRegex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/

  const englishValidation = (rule, value, callback) => {
    if (value && !englishRegex.test(value)) {
      callback('English only.')
    } else {
      callback()
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      window.location.replace('/profile')
    }
  }, [isLoggedIn])

  const handleDiscordAuthCallback = useCallback(
    async (code) => {
      try {
        const { data } = await getDiscordInfo(code, process.env.REACT_APP_REGISTER_REDIRECT_URL)
        if (data?.profileUrl && data?.discordId) {
          setProfile({ image: data?.profileUrl, name: data?.name })
          form.setFieldValue('discordId', data?.discordId)
        } else {
          throw new Error('Error connect discord')
        }
      } catch (error) {
        NotiMessage.error(error.message)
      }
    },
    [form, getDiscordInfo],
  )

  useEffect(() => {
    if (qs?.code) {
      handleDiscordAuthCallback(qs?.code)
    }
  }, [qs?.code, handleDiscordAuthCallback])

  useEffect(() => {
    if (profile?.image) {
      getBase64(profile?.image, (result) => {
        form.setFieldValue('avatarUrl', result)
      })
    }
  }, [profile])

  useEffect(() => {
    if (message && !error) {
      NotiMessage.success(message)
      const payload = { username: form.getFieldValue('username'), password: form.getFieldValue('password') }
      dispatch(login(payload))
      dispatch(clearMessage())
      setTimeout(() => {
        window.location.replace('/')
      }, 1000)
    }
  }, [message, dispatch])

  function getBase64(imageUrl, callBack) {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = imageUrl
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      const base64Img = canvas.toDataURL('image/png')
      callBack(base64Img)
    }
  }

  return (
    <DefaultLayout>
      <Card title={<Title>{t('register.name')}</Title>} bodyStyle={{ display: 'flex', justifyContent: 'center' }}>
        {qs?.code && !isEmpty(profile) ? (
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={8} className={Style.image_container}>
              <ImageAntd src={profile?.image} className={Style.image_avatar} />
              <Title level={4}>{profile?.name}</Title>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Form
                layout="vertical"
                initialValues={{ email: '', firstName: '', password: '', fiveMUsername: '', username: '', lastName: '', confirmPassword: '', fiveMNameServer: '', avatarUrl: '', discordId: '' }}
                form={form}
                onFinish={(val) => {
                  let payload = val
                  delete payload.confirmPassword
                  dispatch(register(payload))
                }}
              >
                <Row gutter={[20, 20]}>
                  {FORM_CONSTANT(t).map((layout, index) => (
                    <Col Col xs={24} sm={24} md={12} key={index}>
                      {layout.form.map((formInfo) =>
                        formInfo.name === 'confirmPassword' ? (
                          <Form.Item
                            label={formInfo.label}
                            name={formInfo.name}
                            key={formInfo.name}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: `${t('register.please_input_your')} ${formInfo.label}`,
                              },
                              { validator: englishValidation },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                  }
                                  return Promise.reject(new Error('The two passwords that you entered do not match!'))
                                },
                              }),
                            ]}
                          >
                            <Input type="password" disabled={formInfo.disable} />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            label={formInfo.label}
                            name={formInfo.name}
                            key={formInfo.name}
                            rules={[
                              {
                                required: formInfo.required,
                                message: `${t('register.please_input_your')} ${formInfo.label}`,
                              },
                              { validator: englishValidation },
                            ]}
                          >
                            <Input type={formInfo.type} disabled={formInfo.disable} />
                          </Form.Item>
                        ),
                      )}
                    </Col>
                  ))}
                  <Input.TextArea defaultValue={TERMS} rows={10} />
                  <Checkbox onChange={(e) => setIsAccept(e.target.checked)}>{t('register.read_terms')}</Checkbox>
                  <Form.Item name="avatarUrl" hidden></Form.Item>
                  <Form.Item name="discordId" hidden></Form.Item>
                  <Col xs={24} sm={24} className={Style.btn_container}>
                    <Form.Item type="submit">
                      <Button type="primary" htmlType="submit" disabled={isAccept === true ? false : true}>
                        {t('register.submit')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <Link to={process.env.REACT_APP_REGISTER_CALLBACK}>
            <Button type="primary" style={{ margin: '50px' }} size="large">
              <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', height: '100%', gap: '10px' }}>
                <img src={DiscordLogo} alt="discord" style={{ height: '100%' }} />
                <p>{`Connect to Discord`}</p>
              </div>
            </Button>
          </Link>
        )}
      </Card>
    </DefaultLayout>
  )
}

export default Register
