import moment from 'moment'

export const columns = () => {
  return [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      key: 'admin',
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Date',
      key: 'date',
      render: (record) => moment(record?.created_at).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Remark',
      dataIndex: 'reason',
      key: 'reason',
      width: '400px',
    },
  ]
}
