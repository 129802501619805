import { Button, Form, Input, message, Modal, Select, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { columns } from './constant'
import { getAllUser } from 'store/userSlice'
import { addUserProductApi, editUserProductApi } from 'api'
import { getAllProducts, getAllUserProducts, getCategories } from 'store/productSlice'

const AddIp = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [createForm] = Form.useForm()
  const { categories, userProducts, products, isLoading } = useSelector((state) => state.product)
  const { users } = useSelector((state) => state.user)

  const [filteredData, setFilteredData] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [currentPageSize, setCurrentPageSize] = useState(6)
  const [isOpen, setIsOpen] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [infoToEdit, setInfoToEdit] = useState(false)
  const [cateId, setCateId] = useState(1)
  const [userOptions, setOptions] = useState([])
  const [productOptions, setProductOptions] = useState([])

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getAllUserProducts({ categoryId: 1 }))
    dispatch(getAllUser())
    dispatch(getAllProducts({ page: 1, perPage: 5000000 }))
  }, [dispatch])

  const handleSearch = () => {
    if (searchText) {
      const filtered = userProducts?.filter((item) => item?.username.includes(searchText) || item?.ip.includes(searchText))
      setFilteredData(filtered)
    } else {
      setFilteredData(false)
    }
  }

  useEffect(() => {
    if (isOpen && infoToEdit) {
      form.setFieldsValue(infoToEdit)
    }
  }, [isOpen, infoToEdit, form])

  useEffect(() => {
    if (users) {
      const filtered = users?.map((item) => {
        return { value: item?.username, label: item?.username, key: item?.username }
      })
      setOptions(filtered)
    }
  }, [users])

  useEffect(() => {
    if (products) {
      const filtered = products?.map((item) => {
        return { value: item?.id, label: item?.name, key: item?.id }
      })
      setProductOptions(filtered)
    }
  }, [products])

  const addUserProduct = async (paylod) => {
    const response = await addUserProductApi(paylod)
    const { data } = response
    if (response?.status === 200 && data?.is_success === true) {
      message.success(data?.data?.message)
      createForm.resetFields()
      dispatch(getAllUserProducts({ categoryId: 1 }))
      dispatch(getAllUser())
      dispatch(getAllProducts({ page: 1, perPage: 5000000 }))
    } else {
      message.error(data?.data?.message)
    }
  }
  const editUserProduct = async (paylod) => {
    const response = await editUserProductApi(paylod)
    const { data } = response
    if (response?.status === 200 && data?.is_success === true) {
      message.success(data?.data?.message)
      dispatch(getAllUserProducts({ categoryId: 1 }))
      dispatch(getAllUser())
      dispatch(getAllProducts({ page: 1, perPage: 5000000 }))
    } else {
      message.error(data?.data?.message)
    }
  }
  if (isLoading) {
    return (
      <Spin>
        <div></div>
      </Spin>
    )
  }
  return (
    <div>
      <Button type="primary" onClick={() => setIsCreate(true)}>
        Add IP
      </Button>
      <Select
        style={{ width: '200px', marginLeft: '10px' }}
        defaultValue={cateId}
        onSelect={(val) => {
          setCateId(val)
          dispatch(getAllUserProducts({ categoryId: val }))
        }}
      >
        {categories?.map((cat) => (
          <Select.Option key={cat?.id} value={cat?.id}>
            {cat?.label}
          </Select.Option>
        ))}
      </Select>
      <Input.Search style={{ padding: '20px' }} onChange={(e) => setSearchText(e.target.value)} onPressEnter={handleSearch} onSearch={handleSearch} placeholder="ค้นหาด้วย Username, IP" />
      <Table
        columns={columns(setIsOpen, setInfoToEdit, productOptions)}
        dataSource={filteredData !== false ? filteredData : userProducts}
        loading={isLoading}
        pagination={{ pageSize: currentPageSize }}
        onChange={(page) => setCurrentPageSize(page?.pageSize)}
        scroll={{ x: 'max-content' }}
      />
      <Modal
        open={isCreate}
        onCancel={() => {
          createForm.resetFields()
          setIsCreate(false)
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          layout="vertical"
          initialValues={{ username: '', active: 1, productId: '', ip: '', reason: '' }}
          form={createForm}
          onFinish={(val) => {
            addUserProduct(val)
            setIsCreate(false)
          }}
        >
          <Form.Item name="username" label="Username">
            <Select allowClear showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={userOptions} />
          </Form.Item>

          <Form.Item name="productId" label="Script">
            <Select allowClear showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={productOptions} />
          </Form.Item>

          <Form.Item name="ip" label="IP">
            <Input />
          </Form.Item>

          <Form.Item name="active" label="Status">
            <Input />
          </Form.Item>

          <Form.Item name="reason" label="Reason">
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit" block style={{ marginBottom: '30px' }}>
            บันทึกข้อมูล
          </Button>
        </Form>
      </Modal>
      <Modal
        open={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          layout="vertical"
          initialValues={{ username: '', admin_username: '', active: 1, firstname: '', ip: '', name: '' }}
          form={form}
          onFinish={(val) => {
            editUserProduct({ id: infoToEdit?.id, ...val })
            setIsOpen(false)
          }}
        >
          <Form.Item name="username" label="Username">
            <Select allowClear showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={userOptions} disabled />
          </Form.Item>

          <Form.Item name="productId" label="Script">
            <Select allowClear showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={productOptions} disabled />
          </Form.Item>

          <Form.Item name="ip" label="IP">
            <Input />
          </Form.Item>

          <Form.Item name="active" label="Status">
            <Input />
          </Form.Item>

          <Form.Item name="admin_username" label="admin">
            <Input disabled />
          </Form.Item>

          <Button type="primary" htmlType="submit" block style={{ marginBottom: '30px' }}>
            บันทึกข้อมูล
          </Button>
        </Form>
      </Modal>
    </div>
  )
}

export default AddIp
