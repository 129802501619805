import React from 'react'
import Style from './style.module.scss'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/userSlice'
import { Row, Col, Menu, Typography, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import Inventory from 'assets/images/inventory.svg'
import UserEdit from 'assets/images/edit-info.svg'
import Administrator from 'assets/images/administrator.svg'
import Topup from 'assets/images/top-up.svg'
const { Text } = Typography

const Menus = styled(Menu)`
  &.ant-menu-light.ant-menu-root.ant-menu-inline,
  :where(.css-dev-only-do-not-override-bpmzhs).ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: none;
  }
  &.ant-menu-light.ant-menu-inline .ant-menu-item {
    padding: 0px;
  }
`

export const UserMenuPopOver = ({ profile }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const darkModeEnabled = useSelector((state) => state?.theme?.darkModeEnabled)

  const MenuImage = ({ src }) => {
    return <img src={src} className={darkModeEnabled ? Style.balance_icon_dark : Style.balance_icon} alt="menu" />
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  const items =
    profile?.isAdmin || profile?.isOwner
      ? [
          {
            label: t('menu.my_profile'),
            icon: <MenuImage src={UserEdit} />,
            key: '/profile',
          },
          {
            label: t('menu.admin'),
            icon: <MenuImage src={Administrator} />,
            key: '/mmt-admin',
          },
          {
            label: t('menu.purchases'),
            icon: <MenuImage src={Inventory} />,
            key: '/purchases?type=0&page=1',
          },
          {
            label: t('menu.obfuscate'),
            icon: <MenuImage src={Topup} />,
            key: '/obfuscate',
          },
          {
            label: t('menu.obfuscate_admin'),
            icon: <MenuImage src={Topup} />,
            key: '/encode',
          },
        ]
      : profile?.isObfuscator === true
      ? [
          {
            label: t('menu.my_profile'),
            icon: <MenuImage src={UserEdit} />,
            key: '/profile',
          },
          {
            label: t('menu.purchases'),
            icon: <MenuImage src={Inventory} />,
            key: '/purchases',
          },
          {
            label: t('menu.obfuscate'),
            icon: <MenuImage src={Topup} />,
            key: '/obfuscate',
          },
        ]
      : [
          {
            label: t('menu.my_profile'),
            icon: <MenuImage src={UserEdit} />,
            key: '/profile',
          },
          {
            label: t('menu.purchases'),
            icon: <MenuImage src={Inventory} />,
            key: '/purchases?type=0&page=1&perPage=4',
          },
        ]

  const handleClickMenu = (item) => {
    navigate(item?.key ?? '/')
  }

  return (
    <div>
      <Row className={Style.user_balance_wrapper}>
        <Col span={24}>
          <Text>{t('general.balance')}</Text>
        </Col>
        <Col span={24} className={Style.user_balance_box}>
          <img src={Inventory} className={darkModeEnabled ? Style.balance_icon_dark : Style.balance_icon} alt="balance" />
          <Text className={Style.user_point}>{`${profile?.point ?? 0} ${t('general.point')}`}</Text>
        </Col>
      </Row>
      <Menus onClick={handleClickMenu} mode="inline" inlineCollapsed={false} items={items} className={darkModeEnabled ? Style.user_menu_main_dark : Style.user_menu_main} />
      <Button block className={darkModeEnabled ? Style.logout_dark : Style.logout} onClick={handleLogout}>
        {t('general.logout')}
      </Button>
    </div>
  )
}
