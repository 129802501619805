import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Logo from 'assets/images/logo.png'
const { Text } = Typography

const FooterBar = () => {
  const { t } = useTranslation()
  const FOOTER_MENU = [
    {
      label: t('footer_menu.marketing_for_fivem'),
      isBold: true,
      to: 'https://discord.com/invite/jAeAauWtHc',
      span: 12,
    },
    {
      label: t('footer_menu.contact'),
      isBold: true,
      to: 'https://discord.com/invite/jAeAauWtHc',
      span: 12,
    },
    {
      label: t('footer_menu.script'),
      isBold: false,
      to: 'https://discord.com/invite/jAeAauWtHc',
      span: 24,
    },
    {
      label: t('footer_menu.fashion'),
      isBold: false,
      to: 'https://discord.com/invite/jAeAauWtHc',
      span: 24,
    },
    {
      label: t('footer_menu.tutorial'),
      isBold: false,
      to: 'https://discord.com/invite/jAeAauWtHc',
      span: 24,
    },
  ]
  return (
    <Row>
      <Col style={{ display: 'grid' }} xs={24} sm={16}>
        <img src={Logo} alt="MMT_LOGO" style={{ maxHeight: '50px' }} />
        <Text>{t('general.web_description')}</Text>
      </Col>
      <Col xs={24} sm={8}>
        <Row>
          {FOOTER_MENU.map((item, index) => (
            <Col span={item?.span} style={item?.isBold ? { fontWeight: 'bold' } : { fontWeight: 'normal' }} key={index}>
              <Link to={item.to}>{item.label}</Link>
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24} style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <p>© COPYRIGHT 2023 MMT</p>
        <Link to="/terms">
          <p>{t('general.terms_conditions')}</p>
        </Link>
      </Col>
    </Row>
  )
}

export default FooterBar
