/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories, getProduct } from 'store/productSlice'
import { useTranslation } from 'react-i18next'
import { Form, Input, Upload, Select, Row, Col, Typography, message, Button, Card, Spin } from 'antd'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import TextEditor from './TextEditor'
import Style from './style.module.scss'
import { editProduct } from 'api'
const { Title } = Typography
const Product = ({ infoEdit }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [uploadForm] = Form.useForm()
  const [category, setCategory] = useState(2)
  const [productsInfo, setProductsInfo] = useState([
    {
      'name[0]': '',
      'title[0]': '',
      'resource_name[0]': '',
      'version[0]': '',
      'price[0]': '',
      'price_partner[0]': '',
      'imagesProduct[0]': '',
      'detail[0]': '',
      'categories_id[0]': '',
      'video_link[0]': '',
      'images[0]': '',
      'id[0]': '',
    },
  ])
  const { categories, product, isLoading } = useSelector((state) => state.product)

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getProduct(infoEdit?.id))
    return () => {
      uploadForm.resetFields()
    }
  }, [dispatch])

  useEffect(() => {
    if (product?.length) {
      handlePrefill()
    }
  }, [product])

  const handlePrefill = () => {
    product?.map(async (item, index) => {
      const images = await urlToImageFile(item?.images_url)
      const imageProduct = await urlToImageFile(item?.image_review)
      uploadForm.setFieldsValue({
        [`name[${index}]`]: item?.name,
        [`title[${index}]`]: item?.title,
        [`resource_name[${index}]`]: item?.resource_name,
        [`version[${index}]`]: item?.version,
        [`price[${index}]`]: item?.price,
        [`price_partner[${index}]`]: item?.price_partner,
        [`detail[${index}]`]: item?.detail,
        [`categories_id[${index}]`]: item?.categories_id,
        [`video_link[${index}]`]: item?.video_link,
        [`images[${index}]`]: images,
        [`imagesProduct[${index}]`]: imageProduct,
        [`id[${index}]`]: item?.id,
      })
    })
    setCategory(product?.[0]?.categories_id)
    setProductsInfo(product)
  }

  async function urlToImageFile(url) {
    const response = await fetch(url)
    const blob = await response.blob()
    const filename = url.substring(url.lastIndexOf('/') + 1)
    return new File([blob], filename, { type: blob.type })
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 50
    if (!isLt2M) {
      message.error('Image must smaller than 50MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const handleChange = (info, type, index) => {
    uploadForm.setFieldValue(`${type}[${index}]`, info.file.originFileObj ?? info.file)
  }

  // const handleSetFileListImages = (field, fileList) => {
  //   const imagesProduct = fileList.map((file) => {
  //     return file.originFileObj
  //   })
  //   uploadForm.setFieldValue(field, imagesProduct[0])
  // }

  const handleCustomRequest = (options) => {
    setTimeout(() => {
      options.onSuccess()
    }, 0)
  }

  if (isLoading) {
    return (
      <Spin size="large">
        <div style={{ height: '70vh' }}></div>
      </Spin>
    )
  }

  const handleSubmitForm = async (values) => {
    const formData = new FormData()
    const dataToAppend = productsInfo?.map((item, index) => {
      return {
        [`name[${index}]`]: values?.[`name[${index}]`] ?? values?.[`name[${index}]`] ? values?.[`name[${index}]`] : values?.[`name[${0}]`],
        [`title[${index}]`]: values?.[`title[${index}]`] ?? values?.[`title[${0}]`] ? values?.[`title[${0}]`] : '',
        [`resource_name[${index}]`]: values?.[`resource_name[${index}]`] ?? values?.[`resource_name[${0}]`] ? values?.[`resource_name[${0}]`] : '',
        [`version[${index}]`]: values?.[`version[${index}]`] ?? values?.[`version[${0}]`] ? values?.[`version[${0}]`] : '',
        [`price[${index}]`]: values?.[`price[${index}]`] ? parseInt(values?.[`price[${index}]`]) : parseInt(values?.[`price[${0}]`]),
        [`price_partner[${index}]`]: values?.[`price_partner[${index}]`] ? parseInt(values?.[`price_partner[${index}]`]) : parseInt(values?.[`price_partner[${0}]`]),
        [`imagesProduct[${index}]`]: values?.[`imagesProduct[${index}]`] ? values?.[`imagesProduct[${index}]`] : values?.[`imagesProduct[${0}]`],
        [`detail[${index}]`]: values?.[`detail[${index}]`] ?? values?.[`detail[${0}]`] ? values?.[`detail[${0}]`] : '',
        [`categories_id[${index}]`]: values?.[`categories_id[${index}]`] ?? values?.[`categories_id[${0}]`] ? values?.[`categories_id[${0}]`] : '',
        [`video_link[${index}]`]: values?.[`video_link[${index}]`] ?? values?.[`video_link[${0}]`] ? values?.[`video_link[${0}]`] : '',
        [`scripts[${index}]`]: values?.[`scripts[${index}]`] ? values?.[`scripts[${index}]`] : values?.[`scripts[${0}]`],
        [`images[${index}]`]: values?.[`images[${index}]`] ? values?.[`images[${index}]`] : values?.[`imagesProduct[${0}]`],
        [`id[${index}]`]: values?.[`id[${index}]`] ? values?.[`id[${index}]`] : '',
      }
    })
    dataToAppend.forEach((data, index) => {
      Object.entries(data).forEach(([key, value]) => {
        formData.append(`${key}`, value)
      })
    })
    try {
      const response = await editProduct(formData)
      if (response?.status === 200 && response?.data?.is_success === true) {
        message.success(response?.data?.data?.message)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    } catch (error) {
      message.error(t(error?.response?.data?.message))
    }
  }

  return (
    <Form form={uploadForm} layout="vertical" onFinish={(values) => handleSubmitForm(values)}>
      <>
        <Form.Item
          name={`categories_id[${0}]`}
          rules={[
            {
              required: true,
              message: `${t('admin.upload.please_input')} ${t('admin.upload.categories_id')}`,
            },
          ]}
        >
          <Select
            style={{ width: '200px' }}
            onSelect={(val) => {
              setCategory(val)
              uploadForm.setFieldValue(`categories_id[${0}]`, val)
            }}
          >
            {categories?.map((cat) => (
              <Select.Option key={cat?.id} value={cat?.id}>
                {cat?.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col span={24}>
            <Title level={3}>รายละเอียดหลัก</Title>
          </Col>
          <Col xs={24} sm={6} md={4}>
            <Form.Item
              name={`images[${0}]`}
              rules={[
                {
                  required: true,
                  message: `${t('admin.upload.please_input')} ${t('admin.upload.cover_image')}`,
                },
              ]}
            >
              <div>
                <span>รูปปัจจุบัน</span>
                <img src={productsInfo?.[0].images_url} alt="image" style={{ width: '80%' }} />
              </div>
              <Upload name="avatar" listType="picture-card" showUploadList={true} customRequest={handleCustomRequest} beforeUpload={beforeUpload} onChange={(val) => handleChange(val, 'images', 0)} maxCount={1}>
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    {t('admin.upload.cover_image')}
                  </div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={category === 1 ? 20 : 20}>
            <Card className={Style.product_info} title={t('admin.upload.main_product_info')}>
              <>
                <Row justify="space-around" gutter={[10, 10]}>
                  <Col span={10}>
                    <Form.Item
                      name={`name[${0}]`}
                      label={t('admin.upload.name')}
                      rules={[
                        {
                          required: true,
                          message: `${t('admin.upload.please_input')} ${t('admin.upload.name')}`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={`title[${0}]`}
                      label={t('admin.upload.title')}
                      rules={[
                        {
                          required: true,
                          message: `${t('admin.upload.please_input')} ${t('admin.upload.title')}`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-around" gutter={[10, 10]}>
                  <Col span={10}>
                    <Form.Item
                      name={`resource_name[${0}]`}
                      label={t('admin.upload.resource_name')}
                      rules={[
                        {
                          required: true,
                          message: `${t('admin.upload.please_input')} ${t('admin.upload.resource_name')}`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={`version[${0}]`}
                      label={t('admin.upload.version')}
                      rules={[
                        {
                          required: true,
                          message: `${t('admin.upload.please_input')} ${t('admin.upload.resource_name')}`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>

              <Row justify="space-around" gutter={[10, 10]}>
                <Col span={10}>
                  <Form.Item
                    name={`price[${0}]`}
                    label={t('admin.upload.price')}
                    rules={[
                      {
                        required: true,
                        message: `${t('admin.upload.please_input')} ${t('admin.upload.price')}`,
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name={`price_partner[${0}]`}
                    label={t('admin.upload.partner_price')}
                    rules={[
                      {
                        required: true,
                        message: `${t('admin.upload.please_input')} ${t('admin.upload.partner_price')}`,
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '350px' }}>
                    <span>รูปปัจจุบัน</span>
                    <img src={productsInfo?.[0].image_review} alt="image" style={{ width: '50%' }} />
                  </div>
                  <Form.Item
                    name={`imagesProduct[${0}]`}
                    label={t('admin.upload.product_images')}
                    rules={[
                      {
                        required: true,
                        message: `${t('admin.upload.please_input')} ${t('admin.upload.product_images')}`,
                      },
                    ]}
                  >
                    <Upload listType="picture" showUploadList={true} customRequest={handleCustomRequest} beforeUpload={beforeUpload} onChange={(val) => handleChange(val, 'imagesProduct', 0)}>
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Form.Item
              name={`detail[${0}]`}
              label={t('admin.upload.detail')}
              rules={[
                {
                  required: true,
                  message: `${t('admin.upload.please_input')} ${t('admin.upload.detail')}`,
                },
              ]}
            >
              <TextEditor value={uploadForm.getFieldValue('detail')} setValue={(val) => uploadForm.setFieldValue(`detail[${0}]`, val)} />
            </Form.Item>
            {(category === 1 || category === 4) && (
              <>
                <Form.Item
                  name={`scripts[${0}]`}
                  label={t('admin.upload.upload_file')}
                  initialValue={productsInfo?.[0]?.download_url}
                  rules={[
                    {
                      required: true,
                      message: `${t('admin.upload.please_input')} ${t('admin.upload.upload_file')}`,
                    },
                  ]}
                >
                  <Upload listType="text" showUploadList={true} customRequest={handleCustomRequest} onChange={(val) => handleChange(val, 'scripts', 0)} maxCount={1}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  name={`video_link[${0}]`}
                  label={t('admin.upload.youtube_link')}
                  tooltip="เอามาแค่ id เช่น https://youtube.com/watch?v=xxxxx เอาแค่ xxxxx"
                  rules={[
                    {
                      required: true,
                      message: `${t('admin.upload.please_input')} ${t('admin.upload.youtube_link')}`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            <Form.Item name={`id[${0}]`} hidden></Form.Item>
          </Col>
        </Row>
      </>
      {category !== 1 && (
        <>
          {productsInfo.map(
            (item, index) =>
              index !== 0 && (
                <Row key={index + 'render'}>
                  <Col xs={24} sm={24} md={4}>
                    <Form.Item name={`images[${index}]`}>
                      <div>
                        <span>รูปปัจจุบัน: </span>
                        {productsInfo?.[index].images_url ? <img src={productsInfo?.[index].images_url} alt="image" style={{ width: '80%' }} /> : 'ไม่มี'}
                      </div>
                      <Upload name="avatar" listType="picture-card" showUploadList={true} customRequest={handleCustomRequest} beforeUpload={beforeUpload} onChange={(val) => handleChange(val, 'images', index)} maxCount={1}>
                        <div>
                          <PlusOutlined />
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            {t('admin.upload.cover_image')}
                          </div>
                        </div>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={category === 1 ? 24 : 20}>
                    <Card
                      className={Style.product_info}
                      title={`${t('admin.upload.option')} ${index}`}
                      extra={
                        <Button
                          onClick={() => {
                            setProductsInfo((prev) => {
                              return prev.filter((item, itemIndex) => itemIndex !== index)
                            })
                          }}
                        >
                          {t('admin.upload.delete')}
                        </Button>
                      }
                    >
                      <Row justify="space-around" gutter={[10, 10]}>
                        <Col span={24}>
                          <Form.Item name={`name[${index}]`} label={t('admin.upload.option_name')}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item name={`price[${index}]`} label={t('admin.upload.price')}>
                            <Input type="number" />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item name={`price_partner[${index}]`} label={t('admin.upload.partner_price')}>
                            <Input type="number" />
                          </Form.Item>
                        </Col>
                        <Form.Item name={`id[${index}]`} hidden></Form.Item>
                        <Form.Item name={`imagesProduct[${index}]`} hidden></Form.Item>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              ),
          )}
          <Button
            block
            style={{ marginBottom: '10px' }}
            onClick={() => {
              const newItem = {
                [`name[${productsInfo.length + 1}]`]: '',
                [`title[${productsInfo.length + 1}]`]: '',
                [`resource_name[${productsInfo.length + 1}]`]: '',
                [`version[${productsInfo.length + 1}]`]: '',
                [`price[${productsInfo.length + 1}]`]: '',
                [`price_partner[${productsInfo.length + 1}]`]: '',
              }

              setProductsInfo((prev) => [...prev, newItem])
            }}
          >
            {t('admin.upload.add_option')}
          </Button>
        </>
      )}

      <Button type="primary" htmlType="submit" block>
        {t('admin.upload.update_product')}
      </Button>
    </Form>
  )
}

export default Product
