import { Button, Form, Input, message, Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { columns } from './constant'
import { getAllUser } from 'store/userSlice'
import { editUserApi } from 'api'

const UsersInfo = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { users, isLoading } = useSelector((state) => state.user)
  const [filteredData, setFilteredData] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [currentPageSize, setCurrentPageSize] = useState(6)
  const [isOpen, setIsOpen] = useState(false)
  const [userInfo, setUserInfo] = useState(false)

  useEffect(() => {
    dispatch(getAllUser())
  }, [dispatch])

  const handleSearch = () => {
    if (searchText) {
      const filtered = users?.filter((item) => item?.username.includes(searchText) || item?.email.includes(searchText) || item?.discord_id.includes(searchText))
      setFilteredData(filtered)
    } else {
      setFilteredData(false)
    }
  }

  useEffect(() => {
    if (isOpen && userInfo) {
      form.setFieldsValue(userInfo)
    }
  }, [isOpen, userInfo, form])

  const editUserInfo = async (paylod) => {
    const response = await editUserApi(paylod)
    const { data } = response
    if (response?.status === 200 && data?.is_success === true) {
      message.success(data?.data?.message)
      dispatch(getAllUser())
    } else {
      message.error(data?.data?.message)
    }
  }
  return (
    <div>
      <Input.Search style={{ padding: '20px' }} onChange={(e) => setSearchText(e.target.value)} onPressEnter={handleSearch} onSearch={handleSearch} placeholder="ค้นหาด้วย Username, Email, Discord ID" />
      <Table
        columns={columns(setIsOpen, setUserInfo)}
        dataSource={filteredData !== false ? filteredData : users}
        loading={isLoading}
        pagination={{ pageSize: currentPageSize }}
        onChange={(page) => setCurrentPageSize(page?.pageSize)}
        scroll={{ x: 'max-content' }}
      />
      <Modal open={isOpen} onCancel={() => setIsOpen(false)} footer={null}>
        <Form
          layout="vertical"
          initialValues={{ avatarUrl: '', email: '', firstName: '', fiveMNameServer: '', fiveMUsername: '', lastName: '', username: '' }}
          form={form}
          onFinish={(val) => {
            editUserInfo(val)
            setIsOpen(false)
          }}
        >
          <Form.Item name="username" label="Username">
            <Input disabled />
          </Form.Item>

          <Form.Item name="email" label="Email">
            <Input disabled />
          </Form.Item>
          <Form.Item name="fiveMUsername" label="FiveM Key">
            <Input />
          </Form.Item>
          <Form.Item name="fiveMNameServer" label="FiveM Server Name">
            <Input />
          </Form.Item>
          <Form.Item name="firstName" label="First name">
            <Input disabled />
          </Form.Item>
          <Form.Item name="lastName" label="Last name">
            <Input disabled />
          </Form.Item>
          <Form.Item name="avatarUrl" hidden></Form.Item>

          <Button type="primary" htmlType="submit" block style={{ marginBottom: '30px' }}>
            บันทึกข้อมูล
          </Button>
        </Form>
      </Modal>
    </div>
  )
}

export default UsersInfo
