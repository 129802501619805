import React from 'react'
import Style from './style.module.scss'
import { Tag, Typography } from 'antd'

const { Text } = Typography

export const Header = ({ profile }) => {
  return (
    <div className={Style.user_menu_header}>
      <Text className={Style.username}>{profile?.username ?? ''}</Text>
      <div>
      {profile?.isPartner && <Tag color='gold'>{'Partner'}</Tag>}
      {profile?.isObfuscator && <Tag color='gold'>{'Obfuscator'}</Tag>}
      {profile?.isAdmin && <Tag color='orange'>{'Admin'}</Tag>}
      {profile?.isOwner && <Tag color='red'>{'Owner'}</Tag>}
      </div>
    </div>
  )
}
