import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from 'layouts/DefaultLayout'
import { Row, Col, Typography, Input, message as NotiMessage, Button, Card, Checkbox } from 'antd'
import Style from './style.module.scss'
import { encodeApi } from 'api'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const { Title } = Typography

const Encode = () => {
  const { t } = useTranslation(null)
  const navigate = useNavigate()
  const [code, setCode] = useState({ server: '', client: '', resource: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [isGenNewKey, setIsGenNewKey] = useState(false)
  const { profile } = useSelector((state) => state.user)
  useEffect(() => {
    if (![profile?.isAdmin, profile?.isOwner].includes(true)) {
      navigate('/')
    }
  }, [profile, navigate])

  const generateCode = async () => {
    setIsLoading(true)
    try {
      const payload = {
        server: code?.server,
        client: code?.client,
        resource: code?.resource,
        new_key: isGenNewKey,
      }
      const response = await encodeApi(payload)
      const { data } = response
      if (data?.is_success === true) {
        NotiMessage.success(t('general.success'))
        setCode((prev) => ({ ...prev, ...data?.data }))
      } else {
        NotiMessage.error(data?.data?.message)
      }
    } catch (error) {
      NotiMessage.error(error?.response?.status === 429 ? error?.response?.data : error?.response?.data?.message)
    }
    setIsLoading(false)
  }

  return (
    <DefaultLayout>
      <Card title={<Title>{t('encode.title')}</Title>}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12} md={12} className={Style.container}>
            <h2>{t('encode.server_code')}</h2>
            <Input.TextArea className={Style.obfuscate_text} value={code?.server} onChange={(e) => setCode((prev) => ({ ...prev, server: e.target.value }))} />
          </Col>
          <Col xs={24} sm={12} md={12} className={Style.container}>
            <h2>{t('encode.client_code')}</h2>
            <Input.TextArea className={Style.obfuscate_text} value={code?.client} onChange={(e) => setCode((prev) => ({ ...prev, client: e.target.value }))} />
          </Col>

          <Col span={24} className={Style.footer_container}>
            <Input placeholder="Resource Name" value={code?.resource} onChange={(e) => setCode((prev) => ({ ...prev, resource: e.target.value }))} className={Style.resource_input} />

            <Checkbox onChange={(e) => setIsGenNewKey(e.target.checked)} key="genNewKey">
              Gen key ใหม่
            </Checkbox>

            <Button type="primary" onClick={generateCode} loading={isLoading}>
              {t('general.submit')}
            </Button>
          </Col>
        </Row>
      </Card>
    </DefaultLayout>
  )
}

export default Encode
