import React, { useEffect } from 'react'
import { Row, Col, Typography, Button, message } from 'antd'
import Style from './style.module.scss'
import { addItemToCart } from 'store/cartSlice'
import { useDispatch, useSelector } from 'react-redux'
import { likeProductApi } from 'api'
import { getProduct } from 'store/productSlice'
import useIsLike from 'hooks/useIsLike'
import { useTranslation } from 'react-i18next'
import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'

const { Title, Text } = Typography

const ProductMainInfo = ({ product, darkModeEnabled, productList, setSelectedProduct, mainId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleAddItemToCart = () => {
    dispatch(addItemToCart(product))
  }

  const isLike = useIsLike(product?.user_likes ?? '[]')
  const user = useSelector((state) => state.user.profile)
  const handleLike = async (event) => {
    event.stopPropagation()
    try {
      await likeProductApi(product.id, (result) => {
        if (result.isSuccess) {
          dispatch(getProduct(mainId))
        } else {
          message.error(t('error.failed_to_connect'))
        }
      })
    } catch (error) {
      message.error(t('error.failed_to_connect'))
    }
  }

  useEffect(() => {
    if (typeof productList === 'string') {
      navigate('/store')
    }
  }, [productList, navigate])

  return (
    <Row gutter={[0, 10]}>
      <Col span={24} className={darkModeEnabled ? Style.product_name_container_dark : Style.product_name_container}>
        <Title level={2} className={darkModeEnabled ? Style.product_name_dark : Style.product_name}>
          {product?.name}
        </Title>
        <div className={Style.like_container}>
          {isLike ? (
            <HeartFilled className={darkModeEnabled ? Style.like_icon_dark : Style.like_icon} onClick={(e) => handleLike(e)} />
          ) : (
            <HeartOutlined className={darkModeEnabled ? Style.like_icon_dark : Style.like_icon} onClick={(e) => handleLike(e)} />
          )}
          <Text className={Style.like_count}>{product?.likes_amount}</Text>
        </div>
      </Col>
      {product?.categories_id !== 1 && (
        <Col span={24} className={darkModeEnabled ? Style.pick_product_container_dark : Style.pick_product_container}>
          <Title level={4} className={Style.pick_product}>
            {`${t('product.pick_product')}`}
          </Title>
          <div className={Style.product_type}>
            {Array.isArray(productList) &&
              productList?.map((item, index) => (
                <Button type={product?.id === item?.id ? 'primary' : 'default'} onClick={() => setSelectedProduct(productList?.[index])} key={index}>
                  {item?.name}
                </Button>
              ))}
          </div>
        </Col>
      )}
      <Col span={24} className={darkModeEnabled ? Style.price_container_dark : Style.price_container}>
        <div>
          <Title level={2} className={Style.price_text}>
            {user?.isPartner ? `${product?.price_partner} ${t('general.point')}` : `${product?.price} ${t('general.point')}`}
          </Title>
          <p className={darkModeEnabled ? Style.price_partner_dark : Style.price_partner}>
            {t('general.partner_price')} <Link to="https://discord.com/invite/jAeAauWtHc">Discord</Link>
          </p>
        </div>
        <div className={Style.add_to_cart_container}>
          <Button type="primary" style={{ fontSize: '16px' }} size="large" onClick={handleAddItemToCart}>
            {t('product.add_to_cart')}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default ProductMainInfo
