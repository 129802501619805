import moment from 'moment'
import DefaultProductImage from 'assets/images/product.png'
import { Button } from 'antd'

export const columns = (setIsOpen, setInfoEdit) => {
  return [
    {
      title: 'รูป',
      key: 'image',
      render: (record) => (
        <img
          src={record?.images_url}
          style={{ width: '50px', height: '50px' }}
          alt="edit product"
          onError={(e) => {
            e.target.src = DefaultProductImage
          }}
        />
      ),
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ประเภท',
      dataIndex: 'category_name',
      key: 'category_name',
      sorter: (a, b) => a.category_name.localeCompare(b.category_name),
    },
    {
      title: 'วันที่สร้าง',
      key: 'created_at',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (record) => moment(record.created_at).format('DD/MM/YYYY - HH:MM'),
      defaultSortOrder: 'descend',
    },
    {
      title: 'แก้ไข',
      key: 'edit',
      render: (record) => (
        <Button
          onClick={() => {
            setIsOpen(true)
            setInfoEdit(record)
          }}
        >
          แก้ไข
        </Button>
      ),
    },
  ]
}
