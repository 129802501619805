import { createSlice } from '@reduxjs/toolkit'

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    darkModeEnabled: false,
  },
  reducers: {
    toggleDarkMode: (state) => {
      state.darkModeEnabled = !state.darkModeEnabled
    },
    setMode: (state, action) => {
      state.darkModeEnabled = action.payload
    },
  },
})

export const { toggleDarkMode, setMode } = themeSlice.actions

export default themeSlice.reducer
