/* eslint-disable */
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL
const WE_COMBAT_KEY = process.env.REACT_APP_WE_COMBAT_KEY
const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN')

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    authorization: `Bearer ${AUTH_TOKEN}`,
    'Access-Control-Allow-Origin': '*',
    ['we-combat-key']: WE_COMBAT_KEY,
  },
})

export default instance
