import React from 'react'
import { useSelector } from 'react-redux'
import { Carousel, Row, Col } from 'antd'
import ProductItem from './ProductItem'
import Style from './style.module.scss'

const ProductList = () => {
  const { products } = useSelector((state) => state.product)
  const darkModeEnabled = useSelector((state) => state?.theme?.darkModeEnabled)
  const getTopProductList = () => {
    const filteredProducts = products.slice(1, -1)
    return filteredProducts ?? []
  }

  return (
    <Carousel dots={false} autoplay className={`animate__animated animate__fadeIn ${darkModeEnabled ? Style.carousel_dark : Style.carousel}`}>
      {getTopProductList().map(
        (product, index) =>
          index % 4 === 0 && (
            <div key={index}>
              <Row gutter={[20, 20]} justify="center">
                {products.slice(index, index + 4).map((product) => (
                  <Col xs={24} sm={12} md={12} lg={6} xl={6} key={product.id}>
                    <ProductItem product={product} key={index} />
                  </Col>
                ))}
              </Row>
            </div>
          ),
      )}
    </Carousel>
  )
}

export default ProductList
