/* eslint-disable */
import { Table, DatePicker, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { columns, summaryColumn } from './constant'
import { getAllSell } from 'store/productSlice'
import moment from 'moment'
const { RangePicker } = DatePicker

const AllSell = () => {
  const dispatch = useDispatch()
  const { allSells, isLoading } = useSelector((state) => state.product)
  const [currentPageSize, setCurrentPageSize] = useState(12)
  const [summaryPageSize, setSummaryPageSize] = useState(10)
  const [dateRange, setDateRange] = useState([])
  const defaultDate = [moment(new Date()).startOf('day').subtract(1, 'months'), moment(new Date()).startOf('day')]

  useEffect(() => {
    if (dateRange?.length > 0) {
      dispatch(getAllSell({ from: moment(dateRange[0]).startOf('day').toISOString(), to: moment(dateRange[1]).startOf('day').toISOString() }))
    } else {
      dispatch(getAllSell({ from: defaultDate[0].toISOString(), to: defaultDate[1].toISOString() }))
    }
  }, [dateRange, dispatch])

  return (
    <div>
      <RangePicker
        onChange={(date, dateString) => {
          setDateRange(dateString)
        }}
      />
      <Row gutter={[15, 15]} style={{ marginTop: '20px' }}>
        <Col xs={24} sm={16}>
          <Table columns={columns()} dataSource={allSells?.purchaseHistory} loading={isLoading} pagination={{ pageSize: currentPageSize }} onChange={(page) => setCurrentPageSize(page?.pageSize)} scroll={{ x: 'max-content' }} />
        </Col>
        <Col xs={24} sm={8}>
          <Table
            columns={summaryColumn()}
            dataSource={allSells?.summary}
            loading={isLoading}
            pagination={{ pageSize: summaryPageSize }}
            onChange={(page) => setSummaryPageSize(page?.pageSize)}
            scroll={{ x: 'max-content' }}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>รวม</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>{allSells?.summaryAll}</Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default AllSell
