import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import useIsLogin from './useIsLogin'
import { message } from 'antd'

const useCheckDiscord = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const { profile } = useSelector((state) => state.user)
  const isLoggedIn = useIsLogin()

  useEffect(() => {
    if (isLoggedIn && !profile?.discordId && location.pathname !== '/profile') {
      message.error(t('error.please_connect_discord'))
      navigate('/profile')
    }
  }, [isLoggedIn, location, profile, navigate, t])
}

export default useCheckDiscord
