/* eslint-disable */

import React, { useState } from 'react'
import DefaultLayout from 'layouts/DefaultLayout'
import { Tabs } from 'antd'
import Upload from './Upload'
import AddPoint from './AddPoint'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Products from './Products'
import PropOrders from './PropOrders'
import PropOrdersHistory from './PropOrdersHistory'
import UsersInfo from './UsersInfo'
import QueryString from 'qs'
import { useEffect } from 'react'
import AddIp from './AddIp'
import ScriptInfo from './ScriptInfo'
import AllSell from './AllSell'

const Admin = () => {
  const { profile, isLoading } = useSelector((state) => state?.user)
  const [tab, setTab] = useState('productsManagement')
  const { search } = useLocation()
  const qs = QueryString.parse(search, { ignoreQueryPrefix: true })
  const navigate = useNavigate()
  const OWNER_MENU = [
    {
      key: 'allSell',
      label: 'All Sell',
      children: <AllSell />,
    },
  ]
  const ADMIN_MENU = [
    {
      key: 'addIp',
      label: 'Add IP',
      children: <AddIp />,
    },
    {
      key: 'upload',
      label: 'Upload',
      children: <Upload />,
    },
    {
      key: 'productsManagement',
      label: 'Products Management',
      children: <Products />,
    },
    {
      key: 'addPoint',
      label: 'Add Point',
      children: <AddPoint />,
    },
    {
      key: 'scriptInfo',
      label: 'Script Info',
      children: <ScriptInfo />,
    },
    {
      key: 'propOrders',
      label: 'Prop Orders',
      children: <PropOrders />,
    },
    {
      key: 'propOrdersHistory',
      label: 'Prop Orders History',
      children: <PropOrdersHistory />,
    },
    {
      key: 'usersInfo',
      label: 'Users Info',
      children: <UsersInfo />,
    },
  ]

  const MENU = profile?.isOwner ? [...OWNER_MENU, ...ADMIN_MENU] : profile?.isAdmin && ADMIN_MENU

  useEffect(() => {
    if (qs?.type) {
      setTab(qs?.type)
    }
    if (!qs?.type) {
      navigate(`/mmt-admin?type=addIp`)
      setTab('addIp')
    }
  }, [qs])

  return profile && !isLoading && ![profile?.isAdmin, profile?.isOwner].includes(true) && false ? (
    <Navigate to="/" replace />
  ) : (
    <>
      <DefaultLayout isAdmin>
        <Tabs tabPosition="left" activeKey={tab} items={MENU} onChange={(val) => navigate(`/mmt-admin?${QueryString.stringify({ type: val })}`)} destroyInactiveTabPane />
      </DefaultLayout>
    </>
  )
}

export default Admin
