import QueryString from 'qs'
import { headerConfigs } from 'utils'
import axios from 'utils/axios-instance'

const BASE_URL = process.env.REACT_APP_API_URL

export const getProductApi = async (id) => {
  const response = await axios.get(`${BASE_URL}/products/${id}`, headerConfigs())
  if (response?.status === 200 && response?.data?.is_success) {
    const { data } = response
    return data?.data
  }
  return response?.data?.data?.message ?? false
}

export const getCategoriesApi = async () => {
  const response = await axios.get(`${BASE_URL}/products/categories`, headerConfigs())
  if (response?.status === 200 && response?.data?.is_success) {
    const { data } = response
    return data?.data
  }
  return response?.data?.data?.message ?? false
}

export const getProductByCategoryApi = async (id, page = 1, perPage = 8, search = '') => {
  const queryString = QueryString.stringify({ type: id, page, perPage, search })
  const response = await axios.get(`${BASE_URL}/products/all?${queryString}`, headerConfigs())
  if (response?.status === 200) {
    const { data } = response
    return data
  }
  return response?.data?.data?.message ?? false
}

export const getAllProductsApi = async (page = 1, perPage = 8, search = '') => {
  const queryString = QueryString.stringify({ page, perPage, search })
  const response = await axios.get(`${BASE_URL}/products/all?${queryString}`, headerConfigs())
  const { data } = response
  if (response?.status === 200) {
    return data
  }
  return response?.data?.data?.message ?? false
}
export const getPopularProductApi = async () => {
  const response = await axios.get(`${BASE_URL}/products/popular`, headerConfigs())
  if (response?.status === 200 && response?.data?.is_success) {
    const { data } = response
    return data?.data
  }
  return response?.data?.data?.message ?? false
}

export const buyProductsApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/products/buy`, payload, headerConfigs())
  return response
}

export const getUserProductsApi = async (categoryId = 0, page = 1, perPage = 8, search = '') => {
  const queryString = parseInt(categoryId) === 0 ? { page, perPage, search } : { cateId: categoryId, page, perPage, search }
  const combinedQS = QueryString.stringify(queryString)
  const response = await axios.get(`${BASE_URL}/user-products/all?${combinedQS}`, headerConfigs())
  if (response?.status === 200) {
    const { data } = response
    return data
  }
  return response?.data?.data?.message ?? false
}

export const changeUserProductIpApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/user-products/change-ip`, payload, headerConfigs())
  if (response?.status === 200 && response?.data?.is_success) {
    const { data } = response
    return data
  }
  return response?.data?.data?.message ?? false
}

export const getObfuscateApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/obfuscate/`, payload, headerConfigs())
  return response
}

export const encodeApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/encrypt/start-encrypt/`, payload, headerConfigs())
  return response
}

export const likeProductApi = async (id, callback) => {
  const { data } = await axios.post(`${BASE_URL}/products/${id}/like`, headerConfigs())
  callback({ isSuccess: data?.is_success })
}

export const addProduct = async (payload) => {
  const response = await axios.post(`${BASE_URL}/products/add`, payload, headerConfigs())
  return response
}

export const editProduct = async (payload) => {
  const response = await axios.post(`${BASE_URL}/products/edit`, payload, headerConfigs())
  return response
}

export const getAllUserProductsApi = async ({ categoryId = 0, status }) => {
  const queryString = parseInt(categoryId) === 0 ? { status } : { cateId: categoryId, status }
  const combinedQS = QueryString.stringify(queryString)
  const response = await axios.get(`${BASE_URL}/user-products/all-user-products?${combinedQS}`, headerConfigs())
  return response
}

export const submitSendProduct = async (id) => {
  const response = await axios.post(`${BASE_URL}/user-products/submit-send-product`, { id }, headerConfigs())
  const { data } = response
  if (response?.status === 200 && data?.is_success === true) {
    return data
  }
  return data
}

export const editUserProductApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/user-products/edit`, payload, headerConfigs())
  return response
}

export const addUserProductApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/user-products/add`, payload, headerConfigs())
  return response
}

export const getUserProductsCalculateApi = async () => {
  const response = await axios.get(`${BASE_URL}/user-products/calculate-online`, headerConfigs())
  return response
}

export const getAllSellApi = async ({ to, from }) => {
  const qs = QueryString.stringify({
    toDate: to,
    fromDate: from,
  })
  const response = await axios.get(`${BASE_URL}/user-products/get-all-history?${qs}`, headerConfigs())
  return response
}
