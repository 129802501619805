import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { changePasswordApi, forgotPasswordApi, getAllUserApi, getCreditLogsApi, getProfileApi, loginApi, logoutApi, registerApi, updateProfileApi } from 'api'
const initialState = {
  token: null,
  isLoading: false,
  isLoggedIn: false,
  profile: {},
  message: '',
  error: null,
  users: [],
  creditLogs: [],
}

export const login = createAsyncThunk('user/login', async (credentials, { rejectWithValue }) => {
  try {
    const data = await loginApi(credentials)
    if (data.is_success && data.jwt) {
      return data.jwt
    }
    throw new Error(data)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getProfile = createAsyncThunk('user/getProfile', async (args, { rejectWithValue }) => {
  try {
    const data = await getProfileApi()
    if (data) {
      return data
    }
    throw new Error('GetProfile error')
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getAllUser = createAsyncThunk('user/getAllUser', async (args, { rejectWithValue }) => {
  try {
    const response = await getAllUserApi()
    const { data } = response
    if (response?.status === 200 && data?.is_success) {
      return data?.data
    }
    throw new Error(response?.data?.data?.message)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getCreditLogs = createAsyncThunk('user/getCreditLogs', async (args, { rejectWithValue }) => {
  try {
    const response = await getCreditLogsApi(args)
    const { data } = response
    if (response?.status === 200 && data?.is_success) {
      return data?.data
    }
    throw new Error(response?.data?.data?.message)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const updateProfile = createAsyncThunk('user/updateProfile', async (args, { rejectWithValue }) => {
  try {
    const response = await updateProfileApi(args)
    if (response?.status === 200 && response?.data?.is_success) {
      const { data } = response
      return data?.data?.message
    }
    throw new Error(response?.data?.data?.message)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const changePassword = createAsyncThunk('user/changePassword', async (args, { rejectWithValue }) => {
  try {
    const response = await changePasswordApi(args)
    if (response?.status === 200 && response?.data?.is_success) {
      const { data } = response
      return data?.data?.message
    }
    throw new Error(response?.data?.data?.message)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const register = createAsyncThunk('user/register', async (args, { rejectWithValue }) => {
  try {
    const response = await registerApi(args)
    if (response?.data?.is_success) {
      return response?.data?.data?.message
    }
    throw new Error(response?.data?.data?.message)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (args, { rejectWithValue }) => {
  try {
    const data = await forgotPasswordApi(args)
    if (data) {
      return data?.message
    }
    throw new Error('ForgotPassword error')
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const logout = createAsyncThunk('user/logout', async (args, { rejectWithValue }) => {
  try {
    const data = await logoutApi()
    if (data) {
      return data
    }
    throw new Error('Logout error')
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsloading: (state, action) => {
      state.isLoading = action.payload
    },
    clearError: (state, action) => {
      state.error = null
    },
    clearMessage: (state, action) => {
      state.message = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.token = action?.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.token = null
        state.error = action.payload
      })
      .addCase(logout.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoggedIn = false
        state.token = null
        state.profile = null
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false
        state.isLoggedIn = false
        state.token = null
        state.profile = null
        state.error = action.payload
      })
      .addCase(getProfile.pending, (state, action) => {
        state.isLoading = true
        state.isLoggedIn = false
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoggedIn = true
        state.profile = action?.payload
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isLoading = false
        state.isLoggedIn = false
        state.profile = {}
        state.error = action.payload
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = action?.payload
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(changePassword.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = action?.payload
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = action?.payload
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(register.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = action?.payload
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(getAllUser.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getAllUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.users = action?.payload
      })
      .addCase(getAllUser.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(getCreditLogs.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getCreditLogs.fulfilled, (state, action) => {
        state.isLoading = false
        state.creditLogs = action?.payload
      })
      .addCase(getCreditLogs.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
  },
})

export const { setIsloading, clearError, clearMessage } = userSlice.actions

export default userSlice.reducer
