/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react'
import DefaultLayout from 'layouts/DefaultLayout'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories, getProductByCategory, getAllProducts } from 'store/productSlice'
import { Tabs, Pagination } from 'antd'
import ProductList from './components/ProductList'
import QueryString from 'qs'

const Store = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const qs = QueryString.parse(search, { ignoreQueryPrefix: true })

  const navigate = useNavigate()
  const productState = useSelector((state) => state.product)
  const { pagination, categories, error } = productState
  const [items, setItem] = useState([])

  const [activeKey, setActiveKey] = useState('0')

  const handleSearchUrlCallback = useCallback(() => {
    const getProductByKey = () => {
      switch (qs?.type) {
        case '0':
          dispatch(getAllProducts({ page: qs?.page, perPage: qs?.perPage, search: qs?.search }))
          break
        default:
          dispatch(getProductByCategory({ id: qs?.type, page: qs?.page, perPage: qs?.perPage, search: qs?.search }))
          break
      }
    }

    if (!isNaN(qs?.type)) {
      getProductByKey()
      setActiveKey(qs?.type)
    }
  }, [qs, dispatch])

  useEffect(() => {
    dispatch(getAllProducts({ page: 1 }))
  }, [])

  const handleItems = useCallback(() => {
    const toShowCategories = categories.filter((item) => item.name !== 'FREE')
    const mapItem = toShowCategories?.map((category) => {
      return { key: String(category?.id), label: category?.label, children: <ProductList key={category?.id} /> }
    })
    mapItem.unshift({ key: '0', label: 'All', children: <ProductList /> })
    setItem(mapItem)
  }, [categories])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  useEffect(() => {
    if (categories?.length > 0) {
      handleItems()
    }
  }, [categories, handleItems])

  useEffect(() => {
    if (search) {
      handleSearchUrlCallback()
    }
  }, [search])

  const handleSetPage = (page) => {
    const query = QueryString.stringify({ ...qs, page })
    navigate(`/store?${query}`)
  }

  return error ? (
    <Navigate to="/" replace={true} />
  ) : (
    <DefaultLayout>
      {items && (
        <>
          <Tabs activeKey={activeKey} items={items} onChange={(val) => navigate(`/store?${QueryString.stringify({ ...qs, type: val, page: 1 })}`)} centered destroyInactiveTabPane />
          <Pagination
            current={pagination?.page ?? 1}
            pageSize={pagination?.perPage ?? 10}
            total={pagination?.totalItems ?? 10}
            onChange={(page) => handleSetPage(page)}
            style={{ margin: '20px 10px', display: 'flex', justifyContent: 'flex-end' }}
          />
        </>
      )}
    </DefaultLayout>
  )
}

export default Store
