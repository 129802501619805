/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, Row, Col, Typography, Button, Input, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import Style from './style.module.scss'
import { changeUserProductIp } from 'store/productSlice'
const { Title, Text } = Typography
const { confirm } = Modal

const PurchaseItem = ({ product, triggerUpdateData }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isShowReview, setIsShowReview] = useState(false)
  const [type, setType] = useState(0)
  const [ip, setIp] = useState('')
  const { search } = useLocation()
  const not_show_version_type = [2, 3]

  useEffect(() => {
    if (product?.status === 2 && product?.ip) {
      setIp(product?.ip)
    }
  }, [product])

  useEffect(() => {
    if (search) {
      handleSearchUrl()
    }
  }, [search])

  const handleSearchUrl = () => {
    const id = parseInt(search?.split('=')[1])
    if (!isNaN(id)) {
      setType(id)
    }
  }
  const openDownloadUrl = () => {
    window.open(product?.download_url, '_blank')
  }

  const openKeymasterUrl = () => {
    window.open('https://keymaster.fivem.net/', '_blank')
  }

  const showConfirm = () => {
    confirm({
      title: (
        <Text level={1} className={Style.warning_title}>
          {t('product.confirm_ip_title')}
        </Text>
      ),
      icon: <ExclamationCircleFilled />,
      content: (
        <Text level={1} className={Style.warning_text}>
          {t('product.confirm_ip_warning')}
        </Text>
      ),
      okText: t('general.confirm'),
      cancelText: t('general.cancel'),
      cancelButtonProps: { style: { background: 'rgb(221,51,51)', color: '#fff', border: 'none' } },
      width: '50%',
      onOk() {
        confirm({
          title: (
            <Text level={1} className={Style.warning_title}>
              {t('general.are_you_sure')}
            </Text>
          ),
          icon: <ExclamationCircleFilled />,
          content: product?.ip ? (
            <div>
              <>
                <Text className={Style.warning_text}>{`${t('product.confirm_ip_sure')}`}</Text> <Text className={Style.warning_text_highlight}>{ip + ' '}</Text> <Text className={Style.warning_text}></Text>
              </>
              <br />
              {product?.can_change_ip > 0 ? (
                <>
                  <Text className={Style.warning_text}>{`${t('product.you_have_quota')}`}</Text> <Text className={Style.warning_text_highlight}>{product?.can_change_ip}</Text> <Text className={Style.warning_text}>{t('product.times')}</Text>
                </>
              ) : (
                <>
                  <Text className={Style.warning_text}>{`${t('product.you_have_to_pay')}`}</Text> <Text className={Style.warning_text_highlight}>{product?.change_ip_price + ' '}</Text>
                  <Text className={Style.warning_text}>{t('product.baht')}</Text>
                </>
              )}
            </div>
          ) : (
            <div>
              <Text className={Style.warning_text}>{`${t('product.first_change_ip')}`}</Text> <Text className={Style.warning_text_highlight}>{ip + ' '}</Text> <Text className={Style.warning_text}></Text>
            </div>
          ),
          okText: t('general.confirm'),
          cancelText: t('general.cancel'),
          cancelButtonProps: { style: { background: 'rgb(221,51,51)', color: '#fff', border: 'none' } },
          width: '50%',
          onOk() {
            dispatch(changeUserProductIp({ ip, id: product?.id }))
            setTimeout(() => {
              triggerUpdateData()
            }, 1000)
          },
        })
      },
    })
  }

  return (
    <Card
      hoverable
      className={Style.product_card}
      bodyStyle={{ padding: '14px' }}
      onMouseOver={() => {
        setIsShowReview(true)
      }}
      onMouseOut={() => {
        setIsShowReview(false)
      }}
    >
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={4}>
          <img src={isShowReview ? product?.image_review : product?.images_url} alt={product?.name} className={Style.cover_image} />
        </Col>
        <Col xs={24} sm={24} md={15}>
          <Title level={5} className={Style.product_name}>
            {product?.name}
          </Title>
          {product?.status === 2 && product?.name !== 'Obfuscate' ? (
            <>
              <Title level={5} className={Style.product_description}>
                {`${t('product.change_ip_once_per_week')}`}
              </Title>
              <Row className={Style.action_container} gutter={[0, 10]}>
                <Col span={24}>
                  <Input value={ip} onChange={(e) => setIp(e.target.value)} style={{ maxWidth: '250px', marginRight: '10px' }} />
                  <Button onClick={showConfirm}>{t('product.confirm_ip')}</Button>
                </Col>
                <Col span={24} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Button type="primary" onClick={openDownloadUrl} icon={<DownloadOutlined />}>
                    {t('general.download')}
                  </Button>
                  {product?.version !== product?.last_version && (
                    <Title type="danger" level={5} className={Style.new_version_alert}>
                      {`${t('product.have_new_version')}`}
                    </Title>
                  )}
                </Col>
              </Row>
            </>
          ) : product?.name !== 'Obfuscate' ? (
            <div>
              <Title type="primary" level={5} className={product?.status === 1 ? Style.product_description_sent : Style.product_description}>
                {product?.status === 1 ? `${t('product.file_sent')}` : `${t('product.wait_for_file')}`}
              </Title>
              <Text type="primary" onClick={openKeymasterUrl} style={{ fontSize: '18px', textDecoration: 'underline' }}>
                https://keymaster.fivem.net
              </Text>
            </div>
          ) : (
            <Button
              type="primary"
              style={{ margin: '10px 0px' }}
              onClick={() => {
                navigate('/obfuscate')
              }}
            >
              Go to Obfuscate
            </Button>
          )}
        </Col>
        {!not_show_version_type.includes(type) && (
          <Col xs={24} sm={24} md={5}>
            <Title level={5} className={Style.product_version}>
              {`${t('product.active_version')} ${product?.last_version}`}
            </Title>
            <Title level={5} className={Style.product_version}>
              {`${t('product.latest_version')} ${product?.version}`}
            </Title>
          </Col>
        )}
      </Row>
    </Card>
  )
}

export default PurchaseItem
