import { Input, message, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUserProducts, getCategories } from 'store/productSlice'

import { columns } from './constant'
import { submitSendProduct } from 'api'

const PropOrders = () => {
  const dispatch = useDispatch()
  const { categories, userProducts, isLoading } = useSelector((state) => state.product)
  const [filteredData, setFilteredData] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [currentPageSize, setCurrentPageSize] = useState(6)

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getAllUserProducts({ categoryId: 0, status: 0 }))
  }, [dispatch])

  const handleSearch = () => {
    if (searchText) {
      const filtered = userProducts?.filter((item) => item?.username.includes(searchText))
      setFilteredData(filtered)
    } else {
      setFilteredData(false)
    }
  }

  const submitProduct = async (id) => {
    const res = await submitSendProduct(id)
    if (res?.is_success) {
      message.success(res?.data.message)
      dispatch(getAllUserProducts({ categoryId: 0, status: 0 }))
    } else {
      message.error(res?.data.message)
    }
  }

  return (
    <div>
      <Select
        style={{ width: '200px' }}
        defaultValue={2}
        onSelect={(val) => {
          dispatch(getAllUserProducts({ categoryId: val, status: 0 }))
        }}
      >
        {categories?.map((cat) => (
          <Select.Option key={cat?.id} value={cat?.id}>
            {cat?.label}
          </Select.Option>
        ))}
      </Select>
      <Input.Search style={{ padding: '20px' }} onChange={(e) => setSearchText(e.target.value)} onPressEnter={handleSearch} onSearch={handleSearch} placeholder="ค้นหาด้วย Username" />
      <Table
        columns={columns(submitProduct)}
        dataSource={filteredData !== false ? filteredData : userProducts}
        loading={isLoading}
        pagination={{ pageSize: currentPageSize }}
        onChange={(page) => setCurrentPageSize(page?.pageSize)}
        scroll={{ x: 'max-content' }}
      />
    </div>
  )
}

export default PropOrders
