import { createSlice } from '@reduxjs/toolkit'
const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: [],
    message: null,
    messageType: null,
  },
  reducers: {
    setCart: (state, action) => {
      state.cart = action.payload
    },
    addItemToCart: (state, action) => {
      const isAlreadyAdd = state.cart?.find((item) => item.id === action.payload.id)
      if (!isAlreadyAdd) {
        localStorage.setItem('cart', JSON.stringify([...state.cart, action?.payload]))
        window.dispatchEvent(new Event('storage'))
        state.cart = [...state.cart, action?.payload]
        state.messageType = 'success'
        state.message = 'cart.product_has_been_added'
      } else {
        state.messageType = 'error'
        state.message = 'cart.product_has_been_selected'
      }
    },
    deleteItemById: (state, action) => {
      const filtered = state.cart.filter((item) => item.id !== action.payload)
      localStorage.setItem('cart', JSON.stringify(filtered))
      state.cart = filtered
      state.messageType = 'success'
      state.message = 'cart.product_has_been_removed'
    },
    clearCart: (state, action) => {
      state.cart = []
      localStorage.setItem('cart', JSON.stringify([]))
    },
    clearCartMessage: (state, action) => {
      state.message = null
      state.messageType = null
    },
  },
})

export const { addItemToCart, deleteItemById, clearCart, clearCartMessage, setCart } = cartSlice.actions

export default cartSlice.reducer
