import { Input, Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts } from 'store/productSlice'

import { columns } from './constant'
import Product from './Product'

const Products = () => {
  const dispatch = useDispatch()
  const { products, isLoading } = useSelector((state) => state.product)
  const [filteredData, setFilteredData] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [infoEdit, setInfoEdit] = useState(false)

  useEffect(() => {
    dispatch(getAllProducts({ page: 1, perPage: 5000000 }))
  }, [dispatch])

  const handleSearch = () => {
    if (searchText) {
      const filtered = products?.filter((item) => item?.name.includes(searchText))
      setFilteredData(filtered)
    } else {
      setFilteredData(false)
    }
  }

  return (
    <div>
      <Input.Search style={{ padding: '20px' }} onChange={(e) => setSearchText(e.target.value)} onPressEnter={handleSearch} onSearch={handleSearch} />
      <Table columns={columns(setIsOpen, setInfoEdit)} dataSource={filteredData !== false ? filteredData : products} loading={isLoading} pagination={{ pageSize: 5 }} scroll={{ x: 'max-content' }} />
      <Modal
        open={isOpen}
        onCancel={() => {
          setInfoEdit(false)
          setIsOpen(false)
        }}
        destroyOnClose
        width="80%"
        footer={null}
      >
        <Product infoEdit={infoEdit} />
      </Modal>
    </div>
  )
}

export default Products
