import moment from 'moment'

export const columns = () => {
  return [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Script/Prop',
      dataIndex: 'name',
      key: 'script',
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Date',
      key: 'created_at',
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (record) => moment(record.created_at).format('DD/MM/YYYY - HH:MM'),
      defaultSortOrder: 'descend',
    },
  ]
}
export const summaryColumn = () => {
  return [
    {
      title: 'Item name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      sorter: (a, b) => a.total - b.total,
      defaultSortOrder: 'descend',
    },
  ]
}
