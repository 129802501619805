import { Button } from 'antd'

export const columns = (setIsOpen, setUserInfo) => {
  return [
    {
      title: 'Username',
      dataIndex: 'username',
      width: '15%',
      key: 'username',
    },
    {
      title: 'DiscordId',
      width: '15%',
      key: 'discord_id',
      align: 'center',
      render: (render) => <p>{render?.discord_id ? render?.discord_id : '-'}</p>,
    },
    {
      title: 'Key Name',
      dataIndex: 'fivem_username',
      width: '15%',
      key: 'fivem_username',
    },
    {
      title: 'Server Name',
      dataIndex: 'fivem_name_server',
      width: '15%',
      key: 'fivem_name_server',
    },
    {
      title: 'Full name',
      key: 'full_name',
      width: '15%',
      render: (record) => <p>{`${record?.firstname} ${record?.lastname}`}</p>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '15%',
      key: 'email',
    },
    {
      title: 'Point',
      dataIndex: 'point',
      width: '15%',
      key: 'point',
    },
    {
      title: 'Manage',
      key: 'manage',
      width: '10%',
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            setIsOpen(true)
            const data = {
              avatarUrl: record?.avatar_url,
              email: record?.email,
              firstName: record?.firstname,
              fiveMNameServer: record?.fivem_name_server,
              fiveMUsername: record?.fivem_username,
              lastName: record?.lastname,
              username: record?.username,
            }
            setUserInfo(data)
          }}
        >
          แก้ไข
        </Button>
      ),
    },
  ]
}
