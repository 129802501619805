export const FORM_CONSTANT = (t) => {
  return [
    {
      name: 'oldPassword',
      label: t('profile.old_password'),
      disable: false,
      required: true,
      type: 'password',
    },
    {
      name: 'newPassword',
      label: t('profile.new_password'),
      disable: false,
      required: true,
      type: 'password',
    },
    {
      name: 'confirmNewPassword',
      label: t('profile.confirm_new_password'),
      disable: false,
      required: true,
      type: 'password',
    },
  ]
}
