import React from 'react'
import { Row, Col, Button, Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import Menus from 'components/Menus'
import { ShoppingFilled } from '@ant-design/icons'
import { Header as LoginPopoverHeader } from 'components/LoginPopover/Header'
import { LoginPopover } from 'components/LoginPopover/'
import { Header as UserMenuPopOverHeader } from 'components/UserMenuPopOver/Header'
import { UserMenuPopOver } from 'components/UserMenuPopOver/'
import Style from './style.module.scss'
import Topup from 'assets/images/top-up.svg'
import DarkMode from 'assets/images/dark.png'
import LightMode from 'assets/images/light.png'
import Cart from 'components/Cart'
import Hamster from 'assets/images/hamster.jpg'
const DesktopMenu = ({ onFinish, isLoggedIn, handleClickMenu, activeMenu, items, darkModeEnabled, handleTheme, handleLanguage, profile, isModalOpen, setIsModalOpen, cart }) => {
  const { t } = useTranslation()
  return (
    <Col xs={0} sm={0} md={17}>
      <Row justify="end" gutter={[20, 0]} style={{ height: '100%' }}>
        <Col xs={0} sm={0} md={24} className={Style.desktop_menu}>
          <Menus onClick={handleClickMenu} selectedKeys={activeMenu} mode="horizontal" items={items} style={{ display: 'flex', justifyContent: 'flex-end' }} />
          <div className={Style.right_menu_group}>
            <Button className={Style.menu_button} type="primary" onClick={() => setIsModalOpen(true)}>
              <ShoppingFilled style={{ fontSize: '24px' }} />
              {cart.length > 0 && <p className={darkModeEnabled ? Style.cart_count_dark : Style.cart_count}>{cart.length}</p>}
            </Button>
            <Button
              className={Style.menu_button}
              type="primary"
              onClick={() => {
                window.open('https://discord.gg/jAeAauWtHc', '_blank')
              }}
            >
              <img src={Topup} className={darkModeEnabled ? Style.menu_icon_dark : Style.menu_icon} alt="topup" />
            </Button>
            {!isLoggedIn ? (
              <Popover placement="top" title={() => <LoginPopoverHeader />} content={<LoginPopover onFinish={onFinish} />} trigger="click">
                <Button className={Style.menu_button} type="primary">
                  {t('menu.sign_in')}
                </Button>
              </Popover>
            ) : (
              <Popover placement="top" title={() => <UserMenuPopOverHeader profile={profile} /> } content={<UserMenuPopOver profile={profile} />} trigger="click">
                <img
                  src={profile?.avatarUrl}
                  onError={(e) => {
                    e.target.src = Hamster
                  }}
                  className={Style.profile_picture}
                  alt="profile"
                />
              </Popover>
            )}
            <Button className={Style.menu_button} type="primary" onClick={handleTheme}>
              <img src={darkModeEnabled ? DarkMode : LightMode} className={darkModeEnabled ? Style.menu_icon_dark : Style.menu_icon} alt="mode" />
            </Button>
            <Button className={Style.menu_button} type="primary" onClick={handleLanguage}>
              <div style={{ width: '24px' }}>{i18n?.language?.toUpperCase()}</div>
            </Button>
          </div>
        </Col>
      </Row>
      <Cart isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </Col>
  )
}

export default DesktopMenu
