/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DefaultLayout from 'layouts/DefaultLayout'
import QueryString from 'qs'
import { getDiscordInfo } from 'api'
import { Row, Col, Typography, Form, Input, Upload, Image as ImageAntd, message as NotiMessage, Button, Card } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import Style from './style.module.scss'
import { clearMessage, updateProfile } from 'store/userSlice'
import DiscordLogo from 'assets/images/discord.png'
import Hamster from 'assets/images/hamster.jpg'
import ChangePasswordModal from 'components/ChangePasswordModal'
const { Title } = Typography

const Profile = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { search } = useLocation()
  const qs = QueryString.parse(search, { ignoreQueryPrefix: true })
  const dispatch = useDispatch()
  const { t } = useTranslation(null)
  const [profileInfo, setProfileInfo] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const { profile, message, isLoading } = useSelector((state) => state.user)

  const handleDiscordAuthCallback = useCallback(
    async (code) => {
      try {
        const { data } = await getDiscordInfo(code, process.env.REACT_APP_PROFILE_REDIRECT_URL)
        if (data?.profileUrl && data?.discordId) {
          getBase64fromUrl(data?.profileUrl, (result) => {
            setProfileInfo({ image: result, imageUrl: data?.profileUrl, discordId: data?.discordId })
          })
        } else {
          throw new Error('Error connect discord')
        }
      } catch (error) {
        NotiMessage.error(error.message)
      }
    },
    [getDiscordInfo],
  )

  useEffect(() => {
    form.setFieldsValue({
      avatarUrl: profile?.avatarUrl ?? null,
      email: profile?.email ?? null,
      firstName: profile?.firstName ?? null,
      discordId: profile?.discordId ?? null,
      fiveMNameServer: profile?.fiveMNameServer ?? null,
      fiveMUsername: profile?.fiveMUsername ?? null,
      lastName: profile?.lastName ?? null,
      username: profile?.username ?? null,
    })
    setProfileInfo((prev) => ({ ...prev, discordId: profile?.discordId ?? null }))
  }, [profile])

  useEffect(() => {
    if (message) {
      NotiMessage.success(message)
      dispatch(clearMessage())
    }
  }, [message, dispatch])

  useEffect(() => {
    if (qs?.code && !profileInfo?.discordId) {
      handleDiscordAuthCallback(qs?.code)
    }
  }, [qs?.code, profileInfo, handleDiscordAuthCallback])

  useEffect(() => {
    if (profileInfo?.image && profileInfo?.discordId) {
      form.setFieldValue('discordId', profileInfo?.discordId)
      form.setFieldValue('avatarUrl', profileInfo?.image)
      form.submit()
      navigate('/profile')
      setTimeout(() => {
        window.location.reload()
      }, [1000])
    }
  }, [profileInfo, form])

  const uploadProps = {
    beforeUpload: (file) => {
      const isAllow = ['image/png'].includes(file.type)
      if (!isAllow) {
        message.error(`${file.name} is invalid. Png only`)
        return isAllow || Upload.LIST_IGNORE
      } else {
        getBase64(file, (res) => {
          form.setFieldValue('avatarUrl', res)
          setProfileInfo((prev) => ({ ...prev, image: res }))
        })
        return false
      }
    },
  }

  const FORM_CONSTANT = [
    {
      form: [
        {
          name: 'username',
          label: t('general.username'),
          disable: true,
        },
        {
          name: 'firstName',
          label: t('general.first_name'),
          disable: true,
        },
        {
          name: 'fiveMUsername',
          label: t('general.fivem_username'),
          disable: true,
        },
      ],
    },
    {
      form: [
        {
          name: 'email',
          label: t('general.email'),
          disable: true,
        },
        {
          name: 'lastName',
          label: t('general.last_name'),
          disable: true,
        },
        {
          name: 'fiveMNameServer',
          label: t('general.fivem_servername'),
          disable: true,
        },
      ],
    },
  ]

  function getBase64(file, callBack) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      callBack(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  function getBase64fromUrl(imageUrl, callBack) {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = imageUrl
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = 140
      canvas.height = 120

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      const base64Img = canvas.toDataURL('image/png')
      callBack(base64Img)
    }
  }

  return (
    <DefaultLayout>
      <Card
        title={<Title>{t('profile.name')}</Title>}
        loading={isLoading}
        extra={
          <Button
            type="primary"
            size="large"
            disabled={profile?.discordId ? true : false}
            onClick={() => {
              window.location.replace(process.env.REACT_APP_PROFILE_CALLBACK)
            }}
          >
            <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', height: '100%', gap: '10px' }}>
              <img src={DiscordLogo} alt="discord" style={{ height: '100%' }} />
              <p>{`${profile?.discordId ? 'Already connected' : 'Connect to Discord'}`}</p>
            </div>
          </Button>
        }
      >
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={8} className={Style.image_container}>
            <img
              src={profileInfo?.image ? profileInfo?.image : profile?.avatarUrl}
              className={Style.image_avatar}
              onError={(e) => {
                e.target.src = Hamster
              }}
            />
            <Upload
              {...uploadProps}
              maxCount={1}
              onRemove={(e) => setProfileInfo((prev) => ({ ...prev, image: null }))}
              className={Style.upload_container}
              onChange={(e) => {
                getBase64(file, (res) => {
                  form.setFieldValue('avatarUrl', res)
                  setProfileInfo((prev) => ({ ...prev, image: res }))
                })
              }}
            >
              <Button icon={<UploadOutlined />} className={Style.upload_btn}>
                {t('profile.select_picture')}
              </Button>
            </Upload>
          </Col>
          <Col xs={24} sm={24} md={16}>
            <Form
              layout="vertical"
              form={form}
              onFinish={(val) => {
                dispatch(updateProfile({ ...profile, ...val }))
              }}
              initialValues={{ name: '', title: '', detail: '', video_link: '', categories_id: '', price: '', price_partner: '', resource_name: '', version: '', images: '', scripts: '', imagesProduct: '' }}
            >
              <Row gutter={[20, 20]}>
                {FORM_CONSTANT.map((layout, index) => (
                  <Col Col xs={24} sm={24} md={12} key={index}>
                    {layout.form.map((formInfo) => (
                      <Form.Item label={formInfo.label} name={formInfo.name} key={formInfo.name} required>
                        <Input disabled={formInfo.disable} />
                      </Form.Item>
                    ))}
                  </Col>
                ))}

                <Form.Item name="avatarUrl" hidden></Form.Item>
                <Form.Item name="discordId" hidden></Form.Item>
                <Col xs={24} sm={24} className={Style.btn_container}>
                  <Form.Item type="submit">
                    <Button type="primary" htmlType="submit">
                      {t('profile.confirm_edit')}
                    </Button>
                  </Form.Item>
                  <Button type="primary" danger onClick={() => setIsOpen(true)}>
                    {t('general.change_password')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
      <ChangePasswordModal isOpen={isOpen} handleClose={() => setIsOpen(false)} />
    </DefaultLayout>
  )
}

export default Profile
