import React from 'react'
import { useSelector } from 'react-redux'
import MenuBar from './MenuBar'
import Style from './style.module.scss'
import { Layout, Spin } from 'antd'
import FooterBar from './FooterBar'
import useCheckDiscord from 'hooks/useCheckDiscord'
import { Helmet } from 'react-helmet'

const { Content, Footer } = Layout
const DefaultLayout = ({ children, isLoading, isAdmin = false, ...rest }) => {
  const darkModeEnabled = useSelector((state) => state.theme.darkModeEnabled)
  useCheckDiscord()

  return (
    <div>
      <Helmet>
        <title>Mamuang Studio</title>
        <meta name="description" content="MMT Studio สร้างสรรค์ จำหน่าย และ ผลิตสื่อ สคริปต์ FiveM งานศิลปะดิจิทัล ระดับคุณภาพ" />
        <meta name="keyword" content="MMT Studio สคริปต์ FiveM กาชา FiveM" />
      </Helmet>
      <MenuBar />
      {isLoading ? (
        <Content className={darkModeEnabled ? Style.wrapper_dark : Style.wrapper}>
          <div className={Style.container_loading}>
            <Spin size="large" />
          </div>
        </Content>
      ) : (
        <Content className={darkModeEnabled ? Style.wrapper_dark : Style.wrapper}>
          <div className={isAdmin ? Style.container_admin : Style.container}>{children}</div>
        </Content>
      )}
      <Footer className={Style.footer_wrapper}>
        <div className={Style.footer_container}>
          <FooterBar />
        </div>
      </Footer>
    </div>
  )
}

export default DefaultLayout
