/* eslint-disable */

import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ProtectedRoute } from 'utils'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { clearError as clearUserError, getProfile } from 'store/userSlice'
import { defaultTheme, darkTheme } from 'theme'
import { ConfigProvider, message as NotiMessage, theme } from 'antd'
import { setMode } from 'store/themeSlice'
import Home from 'pages/Home'
import Profile from 'pages/Profile'
import Store from 'pages/store'
import Product from 'pages/store/Product'
import { withTranslation } from 'react-i18next'
import Commission from 'pages/Commission'
import { clearCartMessage, setCart } from 'store/cartSlice'
import { clearMessage as clearProductMessage, clearError as clearProductError } from 'store/productSlice'
import Purchases from 'pages/Purchases'
import Free from 'pages/Free'
import Obfuscate from 'pages/Obfuscate'
import ForgotPassword from 'pages/ForgotPassword'
import Register from 'pages/Register'
import Admin from 'pages/admin'
import Encode from 'pages/Encode'
import Terms from 'pages/TermsAndConditions'
import ConfirmPassword from 'pages/ConfirmPassword'

function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const darkModeEnabled = useSelector((state) => state?.theme?.darkModeEnabled)
  const { token, isLoggedIn } = useSelector((state) => state.user)
  const userError = useSelector((state) => state.user.error)
  const cartMessage = useSelector((state) => state.cart.message)
  const cartMessageType = useSelector((state) => state.cart.messageType)
  const productMessage = useSelector((state) => state.product.message)
  const productMessageType = useSelector((state) => state.product.messageType)
  const productError = useSelector((state) => state.product.error)

  const currentTheme = darkModeEnabled ? { ...darkTheme } : { ...defaultTheme }

  useEffect(() => {
    // eslint-disable-next-line
    const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN')
    if (AUTH_TOKEN) {
      dispatch(getProfile())
    }
  }, [token, dispatch])

  useEffect(() => {
    // eslint-disable-next-line
    const localDarkModeEnabled = JSON.parse(localStorage.getItem('darkModeEnabled'))
    if (localDarkModeEnabled === true) {
      dispatch(setMode(localDarkModeEnabled))
    }
  }, [dispatch])

  useEffect(() => {
    const handleUpdateLocalStorage = (event) => {
      if (event.key === 'cart') {
        localStorage.setItem('cart', event.newValue)
        handleGetLocalStorage()
      }
    }

    const handleGetLocalStorage = () => {
      const cart = JSON.parse(localStorage.getItem('cart'))
      if (cart) {
        dispatch(setCart(cart))
      }
    }

    if (isLoggedIn) {
      handleGetLocalStorage()
      window.addEventListener('storage', (e) => handleUpdateLocalStorage(e))
    }

    return () => {
      window.removeEventListener('storage', (e) => handleUpdateLocalStorage(e))
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (cartMessage) {
      NotiMessage[cartMessageType](t(cartMessage))
      dispatch(clearCartMessage())
    }
    if (productMessage) {
      NotiMessage[productMessageType](productMessage)
      dispatch(clearProductMessage())
    }
  }, [cartMessage, productMessage, productMessageType, dispatch])

  useEffect(() => {
    if (userError) {
      dispatch(clearUserError())
      NotiMessage.error(userError)
      userError?.includes('401') && localStorage.removeItem('AUTH_TOKEN')
    }
    if (productError) {
      NotiMessage.error(productError)
      dispatch(clearProductError())
    }
  }, [userError, productError, dispatch])

  return (
    <ConfigProvider theme={{ ...currentTheme, algorithm: darkModeEnabled ? darkAlgorithm : defaultAlgorithm }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/free" element={<Free />} />
          <Route path="/store" element={<Store />} />
          <Route path="/store/:id" element={<Product />} />
          <Route path="/commission" element={<Commission />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/confirmPassword" element={<ConfirmPassword />} />
          <Route path="/profile" element={<ProtectedRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/obfuscate" element={<ProtectedRoute />}>
            <Route path="/obfuscate" element={<Obfuscate />} />
          </Route>
          <Route path="/encode" element={<ProtectedRoute />}>
            <Route path="/encode" element={<Encode />} />
          </Route>
          <Route path="/purchases" element={<ProtectedRoute />}>
            <Route path="/purchases" element={<Purchases />} />
          </Route>
          <Route path="/mmt-admin" element={<ProtectedRoute />}>
            <Route path="/mmt-admin" element={<Admin />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default withTranslation()(App)
