import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Row, Col, Typography, Button, message } from 'antd'
import { addItemToCart } from 'store/cartSlice'
import { useDispatch, useSelector } from 'react-redux'
import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import Style from './style.module.scss'
import { likeProductApi } from 'api'
import { getPopularProduct } from 'store/productSlice'
import useIsLike from 'hooks/useIsLike'
import DefaultProductImage from 'assets/images/product.png'

const { Title, Text } = Typography

const ProductItem = ({ product, darkModeEnabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isShowReview, setIsShowReview] = useState(false)

  const user = useSelector((state) => state.user.profile)

  const handleClickProduct = () => {
    window.open(`/store/${product?.id}${product?.resource_name ? `-${product?.resource_name}` : ''}`, '_blank').focus()
  }
  const handleAddItemToCart = (event) => {
    event.stopPropagation()
    dispatch(addItemToCart(product))
  }
  const handleLike = async (event) => {
    event.stopPropagation()
    try {
      await likeProductApi(product.id, (result) => {
        if (result.isSuccess) {
          dispatch(getPopularProduct())
        } else {
          message.error(t('error.failed_to_connect'))
        }
      })
    } catch (error) {
      message.error(t('error.failed_to_connect'))
    }
  }

  const isLike = useIsLike(product?.user_likes)

  return (
    <Card
      hoverable
      className={Style.product_card}
      bodyStyle={{ padding: '14px' }}
      onMouseOver={() => {
        setIsShowReview(true)
      }}
      onMouseOut={() => {
        setIsShowReview(false)
      }}
      onClick={handleClickProduct}
    >
      <img
        src={isShowReview ? product?.image_review : product?.images_url}
        onError={(e) => {
          e.target.src = DefaultProductImage
        }}
        alt={product?.name}
        className={Style.cover_image}
      />
      <Row>
        <Col span={16}>
          <Title level={5} className={Style.product_name}>
            {product?.name}
          </Title>
        </Col>
        <Col span={8}>
          <Title level={5} className={product?.price === 0 ? Style.product_price_free : Style.product_price}>
            {user?.isPartner ? `${product?.price_partner} ${t('general.point')}` : `${product?.price} ${t('general.point')}`}
          </Title>
        </Col>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'inline' }}>
            {isLike ? (
              <HeartFilled className={darkModeEnabled ? Style.like_icon_dark : Style.like_icon} onClick={(e) => handleLike(e)} />
            ) : (
              <HeartOutlined className={darkModeEnabled ? Style.like_icon_dark : Style.like_icon} onClick={(e) => handleLike(e)} />
            )}
            <Text className={Style.like_count}>{product?.likes_amount}</Text>
          </div>
          <Button type="primary" style={{ fontSize: '13px' }} size="small" onClick={handleAddItemToCart}>
            {t('product.add_to_cart')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default ProductItem
