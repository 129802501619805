import { useNavigate, Outlet, Navigate } from 'react-router-dom'

export const ProtectedRoute = () => {
  const navigate = useNavigate()
  const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN')

  if (!AUTH_TOKEN) {
    navigate('/')
  }

  return AUTH_TOKEN ? <Outlet /> : <Navigate to="/" replace />
}
