import { Input, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { columns } from './constant'
import { getCalculateProducts } from 'store/productSlice'

const ScriptInfo = () => {
  const dispatch = useDispatch()
  const { userProducts, isLoading } = useSelector((state) => state.product)
  const [filteredData, setFilteredData] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [currentPageSize, setCurrentPageSize] = useState(6)
  useEffect(() => {
    dispatch(getCalculateProducts())
  }, [dispatch])

  const handleSearch = () => {
    if (searchText) {
      const filtered = userProducts?.filter((item) => item?.name.includes(searchText))
      setFilteredData(filtered)
    } else {
      setFilteredData(false)
    }
  }

  return (
    <div>
      <Input.Search style={{ padding: '20px' }} onChange={(e) => setSearchText(e.target.value)} onPressEnter={handleSearch} onSearch={handleSearch} placeholder="ค้นหาด้วยชื่อสินค้า" />
      <Table
        columns={columns()}
        dataSource={filteredData !== false ? filteredData : userProducts}
        loading={isLoading}
        pagination={{ pageSize: currentPageSize }}
        onChange={(page) => setCurrentPageSize(page?.pageSize)}
        scroll={{ x: 'max-content' }}
      />
    </div>
  )
}

export default ScriptInfo
