import { Button } from 'antd'
import moment from 'moment'

export const columns = (setIsOpen, setInfoToEdit, productOptions) => {
  return [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Script',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'Admin',
      dataIndex: 'admin_username',
      key: 'admin_username',
    },
    {
      title: 'Status',
      key: 'status',
      render: (record) => (record?.status ? 'Online' : 'Offline'),
    },
    {
      title: 'Date',
      key: 'date',
      render: (record) => moment(record?.created_at).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'แก้ไข',
      key: 'edit',
      render: (record) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="primary"
            onClick={() => {
              setIsOpen(true)
              const product = productOptions?.find((option) => {
                return option?.label === record?.name && option
              })
              const data = {
                ...record,
                productId: product?.value,
              }
              setInfoToEdit(data)
            }}
          >
            แก้ไข
          </Button>
        </div>
      ),
    },
  ]
}
